@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

:root {
  --real-vh: 100vh; /* Fallback for browsers that do not support env */
}


@supports (height: 100dvh) {
  :root {
    --real-vh: 100dvh; /* Modern browsers that support dynamic viewport units */
  }
}

.full-height {
  height: calc(var(--real-vh) - constant(safe-area-inset-bottom) - 40px); /* For older iOS Safari versions */
  height: calc(var(--real-vh) - env(safe-area-inset-bottom) - 124px); /* For newer iOS Safari versions */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html, body{min-height:100%;}
body{background-attachment: fixed; width:100vw;}


input:focus::placeholder {
  color: transparent;
}

.parent {
  /* width: 300px; */
  /* height: 200px; */
  /* background-color: #ccc; */
  position: relative;
  -webkit-mask: url(#mask);
  mask: url(#mask);
}

.child {
  /* width: 100px; */
  /* height: 100px; */
  background-color: transparent;
  position: absolute;
  /* top: 50px; */
  /* left: 100px; */
}

.panel-header:before,
.panel-header:after {
    content: "";
    height: 2px;
    background: #000;
    top: 50%;
}
.panel-header:before {
    width: 100px;
    left: -100px;
}
.panel-header:after {
    width: 1000px;
    right: -1000px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.edge-blur::before, .edge-blur::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px; /* Adjust the width to control the blur size */
    background: linear-gradient(to left, rgba(248,184,73,1), transparent);
    /* background: linear-gradient(to left, rgb(59 130 246), transparent); */
    /* background: linear-gradient(to left, rgb(168 85 247), transparent); */
}

.edge-blur::before {
    left: 0;
    transform: translateX(-100%);
}

.edge-blur::after {
    right: 0;
    transform: translateX(100%);
    background: linear-gradient(to right, rgba(248,184,73,1), transparent);
    /* background: linear-gradient(to right, rgb(168 85 247), transparent); */
}

/* starry background */
.full-wh {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
}

.bg-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes animStar {
  from {
    -webkit-transform: translateY(-1200px);
  }

  to {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes animStar {
  from {
    -moz-transform: translateY(-1200px);
  }

  to {
    -moz-transform: translateY(0px);
  }
}

@-ms-keyframes animStar {
  from {
    -ms-transform: translateY(-1200px);
  }

  to {
    -ms-transform: translateY(0px);
  }
}

@keyframes animStar {
  from {
    transform: translateY(-1200px);
  }

  to {
    transform: translateY(0px);
  }
}

@-webkit-keyframes animStarBackwards {
  from {
    -webkit-transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes animStarBackwards {
  from {
    -webkit-transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-2000px);
  }
}

@-ms-keyframes animStarBackwards {
  from {
    -webkit-transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-2000px);
  }
}

@keyframes animStarBackwards {
  from {
    -webkit-transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-2000px);
  }
}

.speed-0 {
  /* animation-name: animStarBackwards !important; */
  animation: none !important;
}
.speed-1 {
  -webkit-animation-duration: 50s !important;
  -moz-animation-duration: 50s !important;
  -ms-animation-duration: 50s !important;
  animation-duration: 50s !important;
}
.speed-2 {
  -webkit-animation-duration: 25s !important;
  -moz-animation-duration: 25s !important;
  -ms-animation-duration: 25s !important;
  animation-duration: 25s !important;
}
.speed-3 {
  -webkit-animation-duration: 12.5s !important;
  -moz-animation-duration: 12.5s !important;
  -ms-animation-duration: 12.5s !important;
  animation-duration: 12.5s !important;
}
.speed-4 {
  -webkit-animation-duration: 6.25s !important;
  -moz-animation-duration: 6.25s !important;
  -ms-animation-duration: 6.25s !important;
  animation-duration: 6.25s !important;
}
.speed-5 {
  -webkit-animation-duration: 3.125s !important;
  -moz-animation-duration: 3.125s !important;
  -ms-animation-duration: 3.125s !important;
  animation-duration: 3.125s !important;
}
.speed-6 {
  -webkit-animation-duration: 1.5625s !important;
  -moz-animation-duration: 1.5625s !important;
  -ms-animation-duration: 1.5625s !important;
  animation-duration: 1.5625s !important;

}
#stars {
  width: 1px;
  height: 1px;
  background: transparent;

  @media (prefers-color-scheme: light) {
    box-shadow:
      117px 1613px #000,
      1488px 635px #000,
      944px 914px #000,
      647px 277px #000,
      1792px 1205px #000,
      656px 1517px #000,
      820px 1839px #000,
      1153px 1400px #000,
      870px 13px #000,
      550px 702px #000,
      1155px 1056px #000,
      88px 1709px #000,
      1450px 1090px #000,
      1929px 457px #000,
      1390px 905px #000,
      1771px 269px #000,
      1741px 669px #000,
      432px 64px #000,
      563px 996px #000,
      1918px 1873px #000,
      1845px 1211px #000,
      231px 1503px #000,
      37px 220px #000,
      1970px 495px #000,
      1812px 925px #000,
      67px 1398px #000,
      535px 279px #000,
      1837px 829px #000,
      1945px 685px #000,
      1677px 1817px #000,
      1317px 1415px #000,
      1785px 905px #000,
      1787px 1554px #000,
      802px 1296px #000,
      512px 1101px #000,
      583px 1364px #000,
      336px 558px #000,
      979px 334px #000,
      106px 792px #000,
      204px 34px #000,
      1845px 1763px #000,
      445px 1599px #000,
      386px 453px #000,
      471px 952px #000,
      1466px 1676px #000,
      1885px 303px #000,
      51px 1717px #000,
      1211px 299px #000,
      1546px 1887px #000,
      1067px 33px #000,
      1088px 1326px #000,
      1938px 760px #000,
      470px 648px #000,
      1213px 269px #000,
      1767px 78px #000,
      977px 976px #000,
      1926px 175px #000,
      722px 1512px #000,
      945px 227px #000,
      1811px 99px #000,
      1912px 1406px #000,
      1602px 1243px #000,
      610px 449px #000,
      654px 1393px #000,
      1930px 1193px #000,
      258px 1184px #000,
      89px 265px #000,
      824px 1494px #000,
      1506px 1435px #000,
      1027px 753px #000,
      1px 1197px #000,
      530px 1161px #000,
      864px 1555px #000,
      1610px 1604px #000,
      1035px 1114px #000,
      1456px 133px #000,
      1196px 1253px #000,
      361px 1037px #000,
      834px 351px #000,
      436px 1676px #000,
      1194px 1007px #000,
      1141px 647px #000,
      319px 454px #000,
      937px 1769px #000,
      1872px 1013px #000,
      733px 643px #000,
      1250px 511px #000,
      189px 296px #000,
      1639px 163px #000,
      1584px 336px #000,
      1912px 1343px #000,
      1298px 1307px #000,
      1750px 902px #000,
      1129px 845px #000,
      1899px 1470px #000,
      1427px 232px #000,
      1391px 838px #000,
      1225px 1819px #000,
      190px 1366px #000,
      1865px 518px #000,
      203px 1383px #000,
      1455px 614px #000,
      423px 354px #000,
      1678px 1790px #000,
      241px 608px #000,
      1089px 730px #000,
      1342px 38px #000,
      1848px 249px #000,
      1874px 1785px #000,
      1040px 1837px #000,
      751px 261px #000,
      510px 1975px #000,
      52px 795px #000,
      1786px 1310px #000,
      498px 712px #000,
      190px 375px #000,
      1341px 722px #000,
      43px 1394px #000,
      1821px 1687px #000,
      106px 130px #000,
      1717px 1978px #000,
      168px 151px #000,
      183px 740px #000,
      945px 1381px #000,
      669px 1170px #000,
      1285px 1816px #000,
      110px 1217px #000,
      1623px 813px #000,
      869px 647px #000,
      867px 582px #000,
      735px 1240px #000,
      519px 1896px #000,
      132px 156px #000,
      1649px 193px #000,
      241px 1109px #000,
      643px 484px #000,
      574px 1282px #000,
      1952px 564px #000,
      1978px 145px #000,
      329px 903px #000,
      1674px 617px #000,
      1978px 558px #000,
      1808px 1715px #000,
      1526px 1238px #000,
      475px 1330px #000,
      810px 425px #000,
      1709px 634px #000,
      1658px 336px #000,
      425px 194px #000,
      352px 96px #000,
      148px 180px #000,
      1139px 1046px #000,
      1809px 1233px #000,
      1669px 171px #000,
      263px 1394px #000,
      534px 715px #000,
      396px 1008px #000,
      589px 1445px #000,
      1190px 381px #000,
      1709px 279px #000,
      520px 891px #000,
      1136px 1867px #000,
      1280px 1233px #000,
      836px 296px #000,
      1348px 646px #000,
      1539px 913px #000,
      423px 781px #000,
      1271px 1805px #000,
      696px 564px #000,
      1549px 804px #000,
      303px 1555px #000,
      1449px 1903px #000,
      66px 687px #000,
      1164px 856px #000,
      1958px 1326px #000,
      125px 157px #000,
      508px 1669px #000,
      465px 725px #000,
      1925px 1440px #000,
      405px 793px #000,
      278px 110px #000,
      1084px 1065px #000,
      1077px 705px #000,
      663px 1844px #000,
      734px 263px #000,
      870px 1761px #000,
      103px 1169px #000,
      1506px 1295px #000,
      1883px 926px #000,
      335px 1361px #000,
      1126px 1284px #000,
      257px 1165px #000,
      837px 580px #000,
      1211px 1362px #000,
      1137px 1380px #000,
      135px 632px #000,
      1491px 1965px #000,
      1098px 195px #000,
      506px 417px #000,
      693px 1243px #000,
      622px 1862px #000,
      1412px 1343px #000,
      948px 1894px #000,
      1315px 1363px #000,
      754px 1098px #000,
      1931px 930px #000,
      1831px 342px #000,
      1751px 1839px #000,
      84px 775px #000,
      1662px 1488px #000,
      617px 1769px #000,
      1869px 1292px #000,
      963px 432px #000,
      371px 1114px #000,
      37px 642px #000,
      21px 1184px #000,
      602px 366px #000,
      414px 524px #000,
      282px 244px #000,
      1689px 868px #000,
      943px 681px #000,
      898px 679px #000,
      449px 1774px #000,
      1678px 1313px #000,
      475px 1811px #000,
      1146px 1509px #000,
      1151px 1863px #000,
      1617px 846px #000,
      82px 1077px #000,
      324px 1317px #000,
      1516px 885px #000,
      1706px 1526px #000,
      1925px 1180px #000,
      553px 967px #000,
      1072px 536px #000,
      1715px 1816px #000,
      185px 286px #000,
      1362px 1600px #000,
      628px 1938px #000,
      1187px 412px #000,
      569px 211px #000,
      1959px 1356px #000,
      1571px 105px #000,
      319px 1111px #000,
      36px 1364px #000,
      502px 1788px #000,
      1051px 1993px #000,
      1617px 773px #000,
      424px 1507px #000,
      1623px 1955px #000,
      307px 662px #000,
      183px 1048px #000,
      1919px 1453px #000,
      1006px 1817px #000,
      468px 673px #000,
      1142px 1375px #000,
      1228px 443px #000,
      1734px 552px #000,
      20px 1041px #000,
      1783px 334px #000,
      98px 1237px #000,
      1356px 1940px #000,
      853px 1779px #000,
      1910px 560px #000,
      1174px 1656px #000,
      110px 1724px #000,
      542px 1771px #000,
      1758px 1931px #000,
      1463px 1401px #000,
      1155px 84px #000,
      1504px 835px #000,
      750px 322px #000,
      407px 1900px #000,
      1600px 1141px #000,
      657px 886px #000,
      526px 714px #000,
      18px 836px #000,
      1546px 1548px #000,
      22px 469px #000,
      594px 1466px #000,
      1160px 1078px #000,
      627px 1055px #000,
      195px 699px #000,
      1099px 684px #000,
      530px 551px #000,
      1160px 1325px #000,
      894px 727px #000,
      1157px 98px #000,
      136px 1483px #000,
      1875px 1975px #000,
      1803px 566px #000,
      318px 1073px #000,
      1866px 1656px #000,
      543px 414px #000,
      719px 474px #000,
      1115px 738px #000,
      353px 875px #000,
      184px 1938px #000,
      1854px 1534px #000,
      420px 1698px #000,
      1480px 1550px #000,
      522px 203px #000,
      1897px 1904px #000,
      975px 1708px #000,
      1774px 602px #000,
      1908px 274px #000,
      61px 715px #000,
      983px 1156px #000,
      326px 1013px #000,
      641px 290px #000,
      1522px 120px #000,
      405px 1637px #000,
      1021px 1099px #000,
      631px 1145px #000,
      982px 1967px #000,
      200px 651px #000,
      795px 351px #000,
      790px 1082px #000,
      144px 1572px #000,
      1542px 901px #000,
      158px 1524px #000,
      849px 1843px #000,
      1807px 203px #000,
      1747px 45px #000,
      1603px 1738px #000,
      617px 1966px #000,
      342px 748px #000,
      1779px 1173px #000,
      1428px 152px #000,
      589px 1998px #000,
      1940px 1838px #000,
      115px 272px #000,
      1217px 1395px #000,
      1402px 1491px #000,
      1833px 1814px #000,
      243px 966px #000,
      319px 578px #000,
      813px 364px #000,
      669px 882px #000,
      551px 134px #000,
      1819px 920px #000,
      740px 1826px #000,
      1021px 952px #000,
      1575px 453px #000,
      324px 419px #000,
      929px 417px #000,
      885px 1112px #000,
      503px 187px #000,
      1908px 362px #000,
      1063px 1601px #000,
      169px 1792px #000,
      789px 963px #000,
      1697px 948px #000,
      1761px 1810px #000,
      1844px 1591px #000,
      1709px 949px #000,
      1402px 1396px #000,
      1037px 225px #000,
      1832px 518px #000,
      1728px 1782px #000,
      194px 1421px #000,
      1395px 742px #000,
      1478px 1325px #000,
      40px 593px #000,
      1732px 117px #000,
      51px 158px #000,
      1598px 1672px #000,
      701px 849px #000,
      1403px 1979px #000,
      145px 1414px #000,
      550px 906px #000,
      1366px 460px #000,
      142px 1379px #000,
      34px 1864px #000,
      1346px 308px #000,
      293px 998px #000,
      21px 1868px #000,
      540px 1033px #000,
      60px 746px #000,
      1602px 1476px #000,
      180px 804px #000,
      345px 1982px #000,
      1439px 640px #000,
      939px 1834px #000,
      20px 432px #000,
      492px 1549px #000,
      109px 1579px #000,
      1796px 1403px #000,
      1079px 519px #000,
      1664px 389px #000,
      1627px 1061px #000,
      823px 419px #000,
      1399px 1882px #000,
      1906px 344px #000,
      1189px 848px #000,
      117px 882px #000,
      1262px 33px #000,
      1048px 434px #000,
      1208px 1309px #000,
      1616px 408px #000,
      1833px 853px #000,
      1433px 1656px #000,
      811px 1861px #000,
      439px 1672px #000,
      1105px 248px #000,
      328px 1652px #000,
      13px 1658px #000,
      685px 987px #000,
      985px 403px #000,
      1664px 1206px #000,
      1993px 1925px #000,
      440px 917px #000,
      1835px 319px #000,
      1404px 1907px #000,
      624px 1443px #000,
      843px 954px #000,
      478px 1567px #000,
      895px 1602px #000,
      1231px 871px #000,
      1267px 1646px #000,
      475px 334px #000,
      784px 796px #000,
      1294px 199px #000,
      109px 702px #000,
      1978px 362px #000,
      291px 940px #000,
      971px 1343px #000,
      74px 719px #000,
      36px 715px #000,
      1007px 1423px #000,
      860px 314px #000,
      631px 177px #000,
      1900px 1590px #000,
      1239px 1348px #000,
      1346px 1270px #000,
      1934px 1475px #000,
      1553px 559px #000,
      588px 1969px #000,
      670px 1269px #000,
      1484px 376px #000,
      20px 1424px #000,
      1396px 8px #000,
      969px 244px #000,
      1807px 538px #000,
      1873px 891px #000,
      636px 1142px #000,
      1474px 1562px #000,
      763px 350px #000,
      663px 700px #000,
      500px 1469px #000,
      1302px 722px #000,
      181px 291px #000,
      266px 893px #000,
      1403px 654px #000,
      492px 460px #000,
      1503px 1369px #000,
      23px 1662px #000,
      349px 333px #000,
      1435px 1017px #000,
      1441px 705px #000,
      1708px 1446px #000,
      1041px 911px #000,
      1063px 780px #000,
      1158px 1356px #000,
      767px 1454px #000,
      1912px 797px #000,
      1731px 1759px #000,
      1378px 1390px #000,
      1815px 1364px #000,
      960px 270px #000,
      1343px 427px #000,
      275px 203px #000,
      1319px 1092px #000,
      1455px 770px #000,
      283px 1503px #000,
      1505px 901px #000,
      1738px 1561px #000,
      1526px 1935px #000,
      1757px 669px #000,
      1640px 620px #000,
      1750px 722px #000,
      748px 66px #000,
      1149px 540px #000,
      159px 953px #000,
      200px 1426px #000,
      515px 1110px #000,
      1552px 737px #000,
      1094px 1459px #000,
      778px 799px #000,
      1031px 523px #000,
      743px 1825px #000,
      1100px 882px #000,
      1088px 1836px #000,
      255px 599px #000,
      67px 1361px #000,
      247px 1721px #000,
      1722px 346px #000,
      1822px 155px #000,
      452px 1973px #000,
      415px 1960px #000,
      1109px 57px #000,
      273px 1392px #000,
      404px 1071px #000,
      1212px 353px #000,
      370px 460px #000,
      795px 1523px #000,
      1932px 340px #000,
      51px 1473px #000,
      1268px 364px #000,
      1512px 1862px #000,
      1678px 1801px #000,
      1796px 579px #000,
      254px 251px #000,
      1466px 1717px #000,
      893px 379px #000,
      1153px 923px #000,
      913px 1808px #000,
      791px 789px #000,
      417px 1924px #000,
      1336px 1599px #000,
      1695px 908px #000,
      1120px 114px #000,
      493px 1949px #000,
      68px 1905px #000,
      969px 481px #000,
      1420px 1095px #000,
      800px 1117px #000,
      390px 234px #000,
      356px 1644px #000,
      1098px 1486px #000,
      1360px 521px #000,
      149px 1198px #000,
      354px 747px #000,
      1749px 487px #000,
      470px 76px #000,
      1672px 289px #000,
      1731px 545px #000,
      1547px 1590px #000,
      498px 692px #000,
      398px 1592px #000,
      1846px 1237px #000,
      1537px 1474px #000,
      1726px 1374px #000,
      1922px 858px #000,
      376px 321px #000,
      985px 227px #000,
      234px 1421px #000,
      760px 745px #000,
      1990px 1132px #000,
      1560px 1597px #000,
      338px 1310px #000,
      1924px 1664px #000,
      547px 1747px #000,
      1639px 1282px #000,
      1202px 337px #000,
      1985px 779px #000,
      737px 456px #000,
      89px 501px #000,
      963px 792px #000,
      655px 1447px #000,
      1492px 1994px #000,
      1171px 254px #000,
      892px 827px #000,
      1735px 442px #000,
      1474px 1187px #000,
      846px 1518px #000,
      557px 1805px #000,
      738px 945px #000,
      795px 68px #000,
      663px 1956px #000,
      1607px 290px #000,
      1524px 15px #000,
      1097px 1911px #000,
      157px 1939px #000,
      935px 1065px #000,
      1809px 1708px #000,
      164px 1157px #000,
      83px 855px #000,
      625px 501px #000,
      814px 398px #000,
      552px 695px #000,
      597px 1546px #000,
      1237px 1417px #000,
      628px 284px #000,
      866px 767px #000,
      1403px 1394px #000,
      765px 1563px #000,
      1648px 109px #000,
      1205px 1659px #000,
      921px 1313px #000,
      1319px 243px #000,
      18px 125px #000,
      7px 777px #000,
      181px 418px #000,
      1062px 1892px #000,
      382px 106px #000,
      994px 751px #000,
      964px 234px #000,
      40px 118px #000,
      278px 706px #000,
      1540px 1978px #000,
      425px 1661px #000,
      1050px 321px #000,
      735px 1729px #000,
      1438px 260px #000,
      1229px 1109px #000,
      186px 1041px #000,
      244px 1184px #000,
      392px 1472px #000,
      670px 1249px #000,
      1260px 1443px #000,
      1977px 1511px #000,
      1240px 773px #000,
      303px 513px #000,
      63px 1530px #000,
      610px 792px #000,
      1987px 1647px #000,
      676px 1597px #000,
      1740px 1244px #000,
      816px 1661px #000,
      351px 802px #000,
      252px 1082px #000,
      31px 365px #000,
      1453px 984px #000,
      667px 1233px #000,
      1247px 1800px #000,
      839px 270px #000,
      775px 913px #000,
      1966px 1398px #000,
      499px 813px #000,
      922px 1982px #000,
      1409px 1902px #000,
      1499px 1766px #000,
      721px 899px #000,
      788px 807px #000,
      989px 1355px #000,
      1248px 1274px #000,
      849px 1091px #000,
      1799px 1036px #000,
      1486px 700px #000,
      170px 1989px #000,
      1275px 799px #000,
      772px 2000px #000,
      1642px 362px #000,
      216px 940px #000,
      1893px 281px #000,
      1944px 1298px #000,
      1294px 400px #000,
      1523px 441px #000,
      1829px 340px #000,
      468px 170px #000,
      1099px 967px #000,
      1331px 665px #000,
      1174px 1553px #000,
      1567px 325px #000,
      1028px 1399px #000,
      781px 1451px #000,
      1912px 1954px #000,
      874px 873px #000,
      1298px 1722px #000,
      1879px 706px #000,
      57px 1221px #000,
      1116px 1432px #000,
      48px 811px #000,
      101px 916px #000,
      677px 304px #000,
      1203px 639px #000,
      1391px 199px #000,
      1895px 1988px #000,
      1462px 1023px #000,
      1216px 1751px #000,
      1261px 663px #000,
      1290px 1119px #000,
      137px 1793px #000,
      1052px 1470px #000,
      1561px 226px #000,
      1156px 402px #000,
      709px 693px #000,
      1040px 1911px #000,
      1624px 1115px #000,
      551px 475px #000,
      416px 1090px #000,
      1183px 451px #000,
      58px 765px #000,
      743px 1016px #000,
      198px 369px #000,
      1645px 1503px #000,
      997px 22px #000,
      1447px 1323px #000,
      379px 883px #000,
      1171px 1195px #000,
      919px 133px #000,
      1400px 517px #000,
      725px 804px #000,
      1600px 699px #000,
      357px 581px #000,
      266px 1713px #000,
      848px 1749px #000,
      1963px 1045px #000,
      119px 1136px #000;
  }

  @media (prefers-color-scheme: dark) {
    box-shadow:
      117px 1613px #fff,
      1488px 635px #fff,
      944px 914px #fff,
      647px 277px #fff,
      1792px 1205px #fff,
      656px 1517px #fff,
      820px 1839px #fff,
      1153px 1400px #fff,
      870px 13px #fff,
      550px 702px #fff,
      1155px 1056px #fff,
      88px 1709px #fff,
      1450px 1090px #fff,
      1929px 457px #fff,
      1390px 905px #fff,
      1771px 269px #fff,
      1741px 669px #fff,
      432px 64px #fff,
      563px 996px #fff,
      1918px 1873px #fff,
      1845px 1211px #fff,
      231px 1503px #fff,
      37px 220px #fff,
      1970px 495px #fff,
      1812px 925px #fff,
      67px 1398px #fff,
      535px 279px #fff,
      1837px 829px #fff,
      1945px 685px #fff,
      1677px 1817px #fff,
      1317px 1415px #fff,
      1785px 905px #fff,
      1787px 1554px #fff,
      802px 1296px #fff,
      512px 1101px #fff,
      583px 1364px #fff,
      336px 558px #fff,
      979px 334px #fff,
      106px 792px #fff,
      204px 34px #fff,
      1845px 1763px #fff,
      445px 1599px #fff,
      386px 453px #fff,
      471px 952px #fff,
      1466px 1676px #fff,
      1885px 303px #fff,
      51px 1717px #fff,
      1211px 299px #fff,
      1546px 1887px #fff,
      1067px 33px #fff,
      1088px 1326px #fff,
      1938px 760px #fff,
      470px 648px #fff,
      1213px 269px #fff,
      1767px 78px #fff,
      977px 976px #fff,
      1926px 175px #fff,
      722px 1512px #fff,
      945px 227px #fff,
      1811px 99px #fff,
      1912px 1406px #fff,
      1602px 1243px #fff,
      610px 449px #fff,
      654px 1393px #fff,
      1930px 1193px #fff,
      258px 1184px #fff,
      89px 265px #fff,
      824px 1494px #fff,
      1506px 1435px #fff,
      1027px 753px #fff,
      1px 1197px #fff,
      530px 1161px #fff,
      864px 1555px #fff,
      1610px 1604px #fff,
      1035px 1114px #fff,
      1456px 133px #fff,
      1196px 1253px #fff,
      361px 1037px #fff,
      834px 351px #fff,
      436px 1676px #fff,
      1194px 1007px #fff,
      1141px 647px #fff,
      319px 454px #fff,
      937px 1769px #fff,
      1872px 1013px #fff,
      733px 643px #fff,
      1250px 511px #fff,
      189px 296px #fff,
      1639px 163px #fff,
      1584px 336px #fff,
      1912px 1343px #fff,
      1298px 1307px #fff,
      1750px 902px #fff,
      1129px 845px #fff,
      1899px 1470px #fff,
      1427px 232px #fff,
      1391px 838px #fff,
      1225px 1819px #fff,
      190px 1366px #fff,
      1865px 518px #fff,
      203px 1383px #fff,
      1455px 614px #fff,
      423px 354px #fff,
      1678px 1790px #fff,
      241px 608px #fff,
      1089px 730px #fff,
      1342px 38px #fff,
      1848px 249px #fff,
      1874px 1785px #fff,
      1040px 1837px #fff,
      751px 261px #fff,
      510px 1975px #fff,
      52px 795px #fff,
      1786px 1310px #fff,
      498px 712px #fff,
      190px 375px #fff,
      1341px 722px #fff,
      43px 1394px #fff,
      1821px 1687px #fff,
      106px 130px #fff,
      1717px 1978px #fff,
      168px 151px #fff,
      183px 740px #fff,
      945px 1381px #fff,
      669px 1170px #fff,
      1285px 1816px #fff,
      110px 1217px #fff,
      1623px 813px #fff,
      869px 647px #fff,
      867px 582px #fff,
      735px 1240px #fff,
      519px 1896px #fff,
      132px 156px #fff,
      1649px 193px #fff,
      241px 1109px #fff,
      643px 484px #fff,
      574px 1282px #fff,
      1952px 564px #fff,
      1978px 145px #fff,
      329px 903px #fff,
      1674px 617px #fff,
      1978px 558px #fff,
      1808px 1715px #fff,
      1526px 1238px #fff,
      475px 1330px #fff,
      810px 425px #fff,
      1709px 634px #fff,
      1658px 336px #fff,
      425px 194px #fff,
      352px 96px #fff,
      148px 180px #fff,
      1139px 1046px #fff,
      1809px 1233px #fff,
      1669px 171px #fff,
      263px 1394px #fff,
      534px 715px #fff,
      396px 1008px #fff,
      589px 1445px #fff,
      1190px 381px #fff,
      1709px 279px #fff,
      520px 891px #fff,
      1136px 1867px #fff,
      1280px 1233px #fff,
      836px 296px #fff,
      1348px 646px #fff,
      1539px 913px #fff,
      423px 781px #fff,
      1271px 1805px #fff,
      696px 564px #fff,
      1549px 804px #fff,
      303px 1555px #fff,
      1449px 1903px #fff,
      66px 687px #fff,
      1164px 856px #fff,
      1958px 1326px #fff,
      125px 157px #fff,
      508px 1669px #fff,
      465px 725px #fff,
      1925px 1440px #fff,
      405px 793px #fff,
      278px 110px #fff,
      1084px 1065px #fff,
      1077px 705px #fff,
      663px 1844px #fff,
      734px 263px #fff,
      870px 1761px #fff,
      103px 1169px #fff,
      1506px 1295px #fff,
      1883px 926px #fff,
      335px 1361px #fff,
      1126px 1284px #fff,
      257px 1165px #fff,
      837px 580px #fff,
      1211px 1362px #fff,
      1137px 1380px #fff,
      135px 632px #fff,
      1491px 1965px #fff,
      1098px 195px #fff,
      506px 417px #fff,
      693px 1243px #fff,
      622px 1862px #fff,
      1412px 1343px #fff,
      948px 1894px #fff,
      1315px 1363px #fff,
      754px 1098px #fff,
      1931px 930px #fff,
      1831px 342px #fff,
      1751px 1839px #fff,
      84px 775px #fff,
      1662px 1488px #fff,
      617px 1769px #fff,
      1869px 1292px #fff,
      963px 432px #fff,
      371px 1114px #fff,
      37px 642px #fff,
      21px 1184px #fff,
      602px 366px #fff,
      414px 524px #fff,
      282px 244px #fff,
      1689px 868px #fff,
      943px 681px #fff,
      898px 679px #fff,
      449px 1774px #fff,
      1678px 1313px #fff,
      475px 1811px #fff,
      1146px 1509px #fff,
      1151px 1863px #fff,
      1617px 846px #fff,
      82px 1077px #fff,
      324px 1317px #fff,
      1516px 885px #fff,
      1706px 1526px #fff,
      1925px 1180px #fff,
      553px 967px #fff,
      1072px 536px #fff,
      1715px 1816px #fff,
      185px 286px #fff,
      1362px 1600px #fff,
      628px 1938px #fff,
      1187px 412px #fff,
      569px 211px #fff,
      1959px 1356px #fff,
      1571px 105px #fff,
      319px 1111px #fff,
      36px 1364px #fff,
      502px 1788px #fff,
      1051px 1993px #fff,
      1617px 773px #fff,
      424px 1507px #fff,
      1623px 1955px #fff,
      307px 662px #fff,
      183px 1048px #fff,
      1919px 1453px #fff,
      1006px 1817px #fff,
      468px 673px #fff,
      1142px 1375px #fff,
      1228px 443px #fff,
      1734px 552px #fff,
      20px 1041px #fff,
      1783px 334px #fff,
      98px 1237px #fff,
      1356px 1940px #fff,
      853px 1779px #fff,
      1910px 560px #fff,
      1174px 1656px #fff,
      110px 1724px #fff,
      542px 1771px #fff,
      1758px 1931px #fff,
      1463px 1401px #fff,
      1155px 84px #fff,
      1504px 835px #fff,
      750px 322px #fff,
      407px 1900px #fff,
      1600px 1141px #fff,
      657px 886px #fff,
      526px 714px #fff,
      18px 836px #fff,
      1546px 1548px #fff,
      22px 469px #fff,
      594px 1466px #fff,
      1160px 1078px #fff,
      627px 1055px #fff,
      195px 699px #fff,
      1099px 684px #fff,
      530px 551px #fff,
      1160px 1325px #fff,
      894px 727px #fff,
      1157px 98px #fff,
      136px 1483px #fff,
      1875px 1975px #fff,
      1803px 566px #fff,
      318px 1073px #fff,
      1866px 1656px #fff,
      543px 414px #fff,
      719px 474px #fff,
      1115px 738px #fff,
      353px 875px #fff,
      184px 1938px #fff,
      1854px 1534px #fff,
      420px 1698px #fff,
      1480px 1550px #fff,
      522px 203px #fff,
      1897px 1904px #fff,
      975px 1708px #fff,
      1774px 602px #fff,
      1908px 274px #fff,
      61px 715px #fff,
      983px 1156px #fff,
      326px 1013px #fff,
      641px 290px #fff,
      1522px 120px #fff,
      405px 1637px #fff,
      1021px 1099px #fff,
      631px 1145px #fff,
      982px 1967px #fff,
      200px 651px #fff,
      795px 351px #fff,
      790px 1082px #fff,
      144px 1572px #fff,
      1542px 901px #fff,
      158px 1524px #fff,
      849px 1843px #fff,
      1807px 203px #fff,
      1747px 45px #fff,
      1603px 1738px #fff,
      617px 1966px #fff,
      342px 748px #fff,
      1779px 1173px #fff,
      1428px 152px #fff,
      589px 1998px #fff,
      1940px 1838px #fff,
      115px 272px #fff,
      1217px 1395px #fff,
      1402px 1491px #fff,
      1833px 1814px #fff,
      243px 966px #fff,
      319px 578px #fff,
      813px 364px #fff,
      669px 882px #fff,
      551px 134px #fff,
      1819px 920px #fff,
      740px 1826px #fff,
      1021px 952px #fff,
      1575px 453px #fff,
      324px 419px #fff,
      929px 417px #fff,
      885px 1112px #fff,
      503px 187px #fff,
      1908px 362px #fff,
      1063px 1601px #fff,
      169px 1792px #fff,
      789px 963px #fff,
      1697px 948px #fff,
      1761px 1810px #fff,
      1844px 1591px #fff,
      1709px 949px #fff,
      1402px 1396px #fff,
      1037px 225px #fff,
      1832px 518px #fff,
      1728px 1782px #fff,
      194px 1421px #fff,
      1395px 742px #fff,
      1478px 1325px #fff,
      40px 593px #fff,
      1732px 117px #fff,
      51px 158px #fff,
      1598px 1672px #fff,
      701px 849px #fff,
      1403px 1979px #fff,
      145px 1414px #fff,
      550px 906px #fff,
      1366px 460px #fff,
      142px 1379px #fff,
      34px 1864px #fff,
      1346px 308px #fff,
      293px 998px #fff,
      21px 1868px #fff,
      540px 1033px #fff,
      60px 746px #fff,
      1602px 1476px #fff,
      180px 804px #fff,
      345px 1982px #fff,
      1439px 640px #fff,
      939px 1834px #fff,
      20px 432px #fff,
      492px 1549px #fff,
      109px 1579px #fff,
      1796px 1403px #fff,
      1079px 519px #fff,
      1664px 389px #fff,
      1627px 1061px #fff,
      823px 419px #fff,
      1399px 1882px #fff,
      1906px 344px #fff,
      1189px 848px #fff,
      117px 882px #fff,
      1262px 33px #fff,
      1048px 434px #fff,
      1208px 1309px #fff,
      1616px 408px #fff,
      1833px 853px #fff,
      1433px 1656px #fff,
      811px 1861px #fff,
      439px 1672px #fff,
      1105px 248px #fff,
      328px 1652px #fff,
      13px 1658px #fff,
      685px 987px #fff,
      985px 403px #fff,
      1664px 1206px #fff,
      1993px 1925px #fff,
      440px 917px #fff,
      1835px 319px #fff,
      1404px 1907px #fff,
      624px 1443px #fff,
      843px 954px #fff,
      478px 1567px #fff,
      895px 1602px #fff,
      1231px 871px #fff,
      1267px 1646px #fff,
      475px 334px #fff,
      784px 796px #fff,
      1294px 199px #fff,
      109px 702px #fff,
      1978px 362px #fff,
      291px 940px #fff,
      971px 1343px #fff,
      74px 719px #fff,
      36px 715px #fff,
      1007px 1423px #fff,
      860px 314px #fff,
      631px 177px #fff,
      1900px 1590px #fff,
      1239px 1348px #fff,
      1346px 1270px #fff,
      1934px 1475px #fff,
      1553px 559px #fff,
      588px 1969px #fff,
      670px 1269px #fff,
      1484px 376px #fff,
      20px 1424px #fff,
      1396px 8px #fff,
      969px 244px #fff,
      1807px 538px #fff,
      1873px 891px #fff,
      636px 1142px #fff,
      1474px 1562px #fff,
      763px 350px #fff,
      663px 700px #fff,
      500px 1469px #fff,
      1302px 722px #fff,
      181px 291px #fff,
      266px 893px #fff,
      1403px 654px #fff,
      492px 460px #fff,
      1503px 1369px #fff,
      23px 1662px #fff,
      349px 333px #fff,
      1435px 1017px #fff,
      1441px 705px #fff,
      1708px 1446px #fff,
      1041px 911px #fff,
      1063px 780px #fff,
      1158px 1356px #fff,
      767px 1454px #fff,
      1912px 797px #fff,
      1731px 1759px #fff,
      1378px 1390px #fff,
      1815px 1364px #fff,
      960px 270px #fff,
      1343px 427px #fff,
      275px 203px #fff,
      1319px 1092px #fff,
      1455px 770px #fff,
      283px 1503px #fff,
      1505px 901px #fff,
      1738px 1561px #fff,
      1526px 1935px #fff,
      1757px 669px #fff,
      1640px 620px #fff,
      1750px 722px #fff,
      748px 66px #fff,
      1149px 540px #fff,
      159px 953px #fff,
      200px 1426px #fff,
      515px 1110px #fff,
      1552px 737px #fff,
      1094px 1459px #fff,
      778px 799px #fff,
      1031px 523px #fff,
      743px 1825px #fff,
      1100px 882px #fff,
      1088px 1836px #fff,
      255px 599px #fff,
      67px 1361px #fff,
      247px 1721px #fff,
      1722px 346px #fff,
      1822px 155px #fff,
      452px 1973px #fff,
      415px 1960px #fff,
      1109px 57px #fff,
      273px 1392px #fff,
      404px 1071px #fff,
      1212px 353px #fff,
      370px 460px #fff,
      795px 1523px #fff,
      1932px 340px #fff,
      51px 1473px #fff,
      1268px 364px #fff,
      1512px 1862px #fff,
      1678px 1801px #fff,
      1796px 579px #fff,
      254px 251px #fff,
      1466px 1717px #fff,
      893px 379px #fff,
      1153px 923px #fff,
      913px 1808px #fff,
      791px 789px #fff,
      417px 1924px #fff,
      1336px 1599px #fff,
      1695px 908px #fff,
      1120px 114px #fff,
      493px 1949px #fff,
      68px 1905px #fff,
      969px 481px #fff,
      1420px 1095px #fff,
      800px 1117px #fff,
      390px 234px #fff,
      356px 1644px #fff,
      1098px 1486px #fff,
      1360px 521px #fff,
      149px 1198px #fff,
      354px 747px #fff,
      1749px 487px #fff,
      470px 76px #fff,
      1672px 289px #fff,
      1731px 545px #fff,
      1547px 1590px #fff,
      498px 692px #fff,
      398px 1592px #fff,
      1846px 1237px #fff,
      1537px 1474px #fff,
      1726px 1374px #fff,
      1922px 858px #fff,
      376px 321px #fff,
      985px 227px #fff,
      234px 1421px #fff,
      760px 745px #fff,
      1990px 1132px #fff,
      1560px 1597px #fff,
      338px 1310px #fff,
      1924px 1664px #fff,
      547px 1747px #fff,
      1639px 1282px #fff,
      1202px 337px #fff,
      1985px 779px #fff,
      737px 456px #fff,
      89px 501px #fff,
      963px 792px #fff,
      655px 1447px #fff,
      1492px 1994px #fff,
      1171px 254px #fff,
      892px 827px #fff,
      1735px 442px #fff,
      1474px 1187px #fff,
      846px 1518px #fff,
      557px 1805px #fff,
      738px 945px #fff,
      795px 68px #fff,
      663px 1956px #fff,
      1607px 290px #fff,
      1524px 15px #fff,
      1097px 1911px #fff,
      157px 1939px #fff,
      935px 1065px #fff,
      1809px 1708px #fff,
      164px 1157px #fff,
      83px 855px #fff,
      625px 501px #fff,
      814px 398px #fff,
      552px 695px #fff,
      597px 1546px #fff,
      1237px 1417px #fff,
      628px 284px #fff,
      866px 767px #fff,
      1403px 1394px #fff,
      765px 1563px #fff,
      1648px 109px #fff,
      1205px 1659px #fff,
      921px 1313px #fff,
      1319px 243px #fff,
      18px 125px #fff,
      7px 777px #fff,
      181px 418px #fff,
      1062px 1892px #fff,
      382px 106px #fff,
      994px 751px #fff,
      964px 234px #fff,
      40px 118px #fff,
      278px 706px #fff,
      1540px 1978px #fff,
      425px 1661px #fff,
      1050px 321px #fff,
      735px 1729px #fff,
      1438px 260px #fff,
      1229px 1109px #fff,
      186px 1041px #fff,
      244px 1184px #fff,
      392px 1472px #fff,
      670px 1249px #fff,
      1260px 1443px #fff,
      1977px 1511px #fff,
      1240px 773px #fff,
      303px 513px #fff,
      63px 1530px #fff,
      610px 792px #fff,
      1987px 1647px #fff,
      676px 1597px #fff,
      1740px 1244px #fff,
      816px 1661px #fff,
      351px 802px #fff,
      252px 1082px #fff,
      31px 365px #fff,
      1453px 984px #fff,
      667px 1233px #fff,
      1247px 1800px #fff,
      839px 270px #fff,
      775px 913px #fff,
      1966px 1398px #fff,
      499px 813px #fff,
      922px 1982px #fff,
      1409px 1902px #fff,
      1499px 1766px #fff,
      721px 899px #fff,
      788px 807px #fff,
      989px 1355px #fff,
      1248px 1274px #fff,
      849px 1091px #fff,
      1799px 1036px #fff,
      1486px 700px #fff,
      170px 1989px #fff,
      1275px 799px #fff,
      772px 2000px #fff,
      1642px 362px #fff,
      216px 940px #fff,
      1893px 281px #fff,
      1944px 1298px #fff,
      1294px 400px #fff,
      1523px 441px #fff,
      1829px 340px #fff,
      468px 170px #fff,
      1099px 967px #fff,
      1331px 665px #fff,
      1174px 1553px #fff,
      1567px 325px #fff,
      1028px 1399px #fff,
      781px 1451px #fff,
      1912px 1954px #fff,
      874px 873px #fff,
      1298px 1722px #fff,
      1879px 706px #fff,
      57px 1221px #fff,
      1116px 1432px #fff,
      48px 811px #fff,
      101px 916px #fff,
      677px 304px #fff,
      1203px 639px #fff,
      1391px 199px #fff,
      1895px 1988px #fff,
      1462px 1023px #fff,
      1216px 1751px #fff,
      1261px 663px #fff,
      1290px 1119px #fff,
      137px 1793px #fff,
      1052px 1470px #fff,
      1561px 226px #fff,
      1156px 402px #fff,
      709px 693px #fff,
      1040px 1911px #fff,
      1624px 1115px #fff,
      551px 475px #fff,
      416px 1090px #fff,
      1183px 451px #fff,
      58px 765px #fff,
      743px 1016px #fff,
      198px 369px #fff,
      1645px 1503px #fff,
      997px 22px #fff,
      1447px 1323px #fff,
      379px 883px #fff,
      1171px 1195px #fff,
      919px 133px #fff,
      1400px 517px #fff,
      725px 804px #fff,
      1600px 699px #fff,
      357px 581px #fff,
      266px 1713px #fff,
      848px 1749px #fff,
      1963px 1045px #fff,
      119px 1136px #fff;
  }

  -webkit-animation: animStar 48s linear infinite;
  -moz-animation: animStar 48s linear infinite;
  -ms-animation: animStar 48s linear infinite;
  animation: animStar 48s linear infinite;
}

#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;

  @media (prefers-color-scheme: light) {
    box-shadow:
      117px 1613px #000,
      1488px 635px #000,
      944px 914px #000,
      647px 277px #000,
      1792px 1205px #000,
      656px 1517px #000,
      820px 1839px #000,
      1153px 1400px #000,
      870px 13px #000,
      550px 702px #000,
      1155px 1056px #000,
      88px 1709px #000,
      1450px 1090px #000,
      1929px 457px #000,
      1390px 905px #000,
      1771px 269px #000,
      1741px 669px #000,
      432px 64px #000,
      563px 996px #000,
      1918px 1873px #000,
      1845px 1211px #000,
      231px 1503px #000,
      37px 220px #000,
      1970px 495px #000,
      1812px 925px #000,
      67px 1398px #000,
      535px 279px #000,
      1837px 829px #000,
      1945px 685px #000,
      1677px 1817px #000,
      1317px 1415px #000,
      1785px 905px #000,
      1787px 1554px #000,
      802px 1296px #000,
      512px 1101px #000,
      583px 1364px #000,
      336px 558px #000,
      979px 334px #000,
      106px 792px #000,
      204px 34px #000,
      1845px 1763px #000,
      445px 1599px #000,
      386px 453px #000,
      471px 952px #000,
      1466px 1676px #000,
      1885px 303px #000,
      51px 1717px #000,
      1211px 299px #000,
      1546px 1887px #000,
      1067px 33px #000,
      1088px 1326px #000,
      1938px 760px #000,
      470px 648px #000,
      1213px 269px #000,
      1767px 78px #000,
      977px 976px #000,
      1926px 175px #000,
      722px 1512px #000,
      945px 227px #000,
      1811px 99px #000,
      1912px 1406px #000,
      1602px 1243px #000,
      610px 449px #000,
      654px 1393px #000,
      1930px 1193px #000,
      258px 1184px #000,
      89px 265px #000,
      824px 1494px #000,
      1506px 1435px #000,
      1027px 753px #000,
      1px 1197px #000,
      530px 1161px #000,
      864px 1555px #000,
      1610px 1604px #000,
      1035px 1114px #000,
      1456px 133px #000,
      1196px 1253px #000,
      361px 1037px #000,
      834px 351px #000,
      436px 1676px #000,
      1194px 1007px #000,
      1141px 647px #000,
      319px 454px #000,
      937px 1769px #000,
      1872px 1013px #000,
      733px 643px #000,
      1250px 511px #000,
      189px 296px #000,
      1639px 163px #000,
      1584px 336px #000,
      1912px 1343px #000,
      1298px 1307px #000,
      1750px 902px #000,
      1129px 845px #000,
      1899px 1470px #000,
      1427px 232px #000,
      1391px 838px #000,
      1225px 1819px #000,
      190px 1366px #000,
      1865px 518px #000,
      203px 1383px #000,
      1455px 614px #000,
      423px 354px #000,
      1678px 1790px #000,
      241px 608px #000,
      1089px 730px #000,
      1342px 38px #000,
      1848px 249px #000,
      1874px 1785px #000,
      1040px 1837px #000,
      751px 261px #000,
      510px 1975px #000,
      52px 795px #000,
      1786px 1310px #000,
      498px 712px #000,
      190px 375px #000,
      1341px 722px #000,
      43px 1394px #000,
      1821px 1687px #000,
      106px 130px #000,
      1717px 1978px #000,
      168px 151px #000,
      183px 740px #000,
      945px 1381px #000,
      669px 1170px #000,
      1285px 1816px #000,
      110px 1217px #000,
      1623px 813px #000,
      869px 647px #000,
      867px 582px #000,
      735px 1240px #000,
      519px 1896px #000,
      132px 156px #000,
      1649px 193px #000,
      241px 1109px #000,
      643px 484px #000,
      574px 1282px #000,
      1952px 564px #000,
      1978px 145px #000,
      329px 903px #000,
      1674px 617px #000,
      1978px 558px #000,
      1808px 1715px #000,
      1526px 1238px #000,
      475px 1330px #000,
      810px 425px #000,
      1709px 634px #000,
      1658px 336px #000,
      425px 194px #000,
      352px 96px #000,
      148px 180px #000,
      1139px 1046px #000,
      1809px 1233px #000,
      1669px 171px #000,
      263px 1394px #000,
      534px 715px #000,
      396px 1008px #000,
      589px 1445px #000,
      1190px 381px #000,
      1709px 279px #000,
      520px 891px #000,
      1136px 1867px #000,
      1280px 1233px #000,
      836px 296px #000,
      1348px 646px #000,
      1539px 913px #000,
      423px 781px #000,
      1271px 1805px #000,
      696px 564px #000,
      1549px 804px #000,
      303px 1555px #000,
      1449px 1903px #000,
      66px 687px #000,
      1164px 856px #000,
      1958px 1326px #000,
      125px 157px #000,
      508px 1669px #000,
      465px 725px #000,
      1925px 1440px #000,
      405px 793px #000,
      278px 110px #000,
      1084px 1065px #000,
      1077px 705px #000,
      663px 1844px #000,
      734px 263px #000,
      870px 1761px #000,
      103px 1169px #000,
      1506px 1295px #000,
      1883px 926px #000,
      335px 1361px #000,
      1126px 1284px #000,
      257px 1165px #000,
      837px 580px #000,
      1211px 1362px #000,
      1137px 1380px #000,
      135px 632px #000,
      1491px 1965px #000,
      1098px 195px #000,
      506px 417px #000,
      693px 1243px #000,
      622px 1862px #000,
      1412px 1343px #000,
      948px 1894px #000,
      1315px 1363px #000,
      754px 1098px #000,
      1931px 930px #000,
      1831px 342px #000,
      1751px 1839px #000,
      84px 775px #000,
      1662px 1488px #000,
      617px 1769px #000,
      1869px 1292px #000,
      963px 432px #000,
      371px 1114px #000,
      37px 642px #000,
      21px 1184px #000,
      602px 366px #000,
      414px 524px #000,
      282px 244px #000,
      1689px 868px #000,
      943px 681px #000,
      898px 679px #000,
      449px 1774px #000,
      1678px 1313px #000,
      475px 1811px #000,
      1146px 1509px #000,
      1151px 1863px #000,
      1617px 846px #000,
      82px 1077px #000,
      324px 1317px #000,
      1516px 885px #000,
      1706px 1526px #000,
      1925px 1180px #000,
      553px 967px #000,
      1072px 536px #000,
      1715px 1816px #000,
      185px 286px #000,
      1362px 1600px #000,
      628px 1938px #000,
      1187px 412px #000,
      569px 211px #000,
      1959px 1356px #000,
      1571px 105px #000,
      319px 1111px #000,
      36px 1364px #000,
      502px 1788px #000,
      1051px 1993px #000,
      1617px 773px #000,
      424px 1507px #000,
      1623px 1955px #000,
      307px 662px #000,
      183px 1048px #000,
      1919px 1453px #000,
      1006px 1817px #000,
      468px 673px #000,
      1142px 1375px #000,
      1228px 443px #000,
      1734px 552px #000,
      20px 1041px #000,
      1783px 334px #000,
      98px 1237px #000,
      1356px 1940px #000,
      853px 1779px #000,
      1910px 560px #000,
      1174px 1656px #000,
      110px 1724px #000,
      542px 1771px #000,
      1758px 1931px #000,
      1463px 1401px #000,
      1155px 84px #000,
      1504px 835px #000,
      750px 322px #000,
      407px 1900px #000,
      1600px 1141px #000,
      657px 886px #000,
      526px 714px #000,
      18px 836px #000,
      1546px 1548px #000,
      22px 469px #000,
      594px 1466px #000,
      1160px 1078px #000,
      627px 1055px #000,
      195px 699px #000,
      1099px 684px #000,
      530px 551px #000,
      1160px 1325px #000,
      894px 727px #000,
      1157px 98px #000,
      136px 1483px #000,
      1875px 1975px #000,
      1803px 566px #000,
      318px 1073px #000,
      1866px 1656px #000,
      543px 414px #000,
      719px 474px #000,
      1115px 738px #000,
      353px 875px #000,
      184px 1938px #000,
      1854px 1534px #000,
      420px 1698px #000,
      1480px 1550px #000,
      522px 203px #000,
      1897px 1904px #000,
      975px 1708px #000,
      1774px 602px #000,
      1908px 274px #000,
      61px 715px #000,
      983px 1156px #000,
      326px 1013px #000,
      641px 290px #000,
      1522px 120px #000,
      405px 1637px #000,
      1021px 1099px #000,
      631px 1145px #000,
      982px 1967px #000,
      200px 651px #000,
      795px 351px #000,
      790px 1082px #000,
      144px 1572px #000,
      1542px 901px #000,
      158px 1524px #000,
      849px 1843px #000,
      1807px 203px #000,
      1747px 45px #000,
      1603px 1738px #000,
      617px 1966px #000,
      342px 748px #000,
      1779px 1173px #000,
      1428px 152px #000,
      589px 1998px #000,
      1940px 1838px #000,
      115px 272px #000,
      1217px 1395px #000,
      1402px 1491px #000,
      1833px 1814px #000,
      243px 966px #000,
      319px 578px #000,
      813px 364px #000,
      669px 882px #000,
      551px 134px #000,
      1819px 920px #000,
      740px 1826px #000,
      1021px 952px #000,
      1575px 453px #000,
      324px 419px #000,
      929px 417px #000,
      885px 1112px #000,
      503px 187px #000,
      1908px 362px #000,
      1063px 1601px #000,
      169px 1792px #000,
      789px 963px #000,
      1697px 948px #000,
      1761px 1810px #000,
      1844px 1591px #000,
      1709px 949px #000,
      1402px 1396px #000,
      1037px 225px #000,
      1832px 518px #000,
      1728px 1782px #000,
      194px 1421px #000,
      1395px 742px #000,
      1478px 1325px #000,
      40px 593px #000,
      1732px 117px #000,
      51px 158px #000,
      1598px 1672px #000,
      701px 849px #000,
      1403px 1979px #000,
      145px 1414px #000,
      550px 906px #000,
      1366px 460px #000,
      142px 1379px #000,
      34px 1864px #000,
      1346px 308px #000,
      293px 998px #000,
      21px 1868px #000,
      540px 1033px #000,
      60px 746px #000,
      1602px 1476px #000,
      180px 804px #000,
      345px 1982px #000,
      1439px 640px #000,
      939px 1834px #000,
      20px 432px #000,
      492px 1549px #000,
      109px 1579px #000,
      1796px 1403px #000,
      1079px 519px #000,
      1664px 389px #000,
      1627px 1061px #000,
      823px 419px #000,
      1399px 1882px #000,
      1906px 344px #000,
      1189px 848px #000,
      117px 882px #000,
      1262px 33px #000,
      1048px 434px #000,
      1208px 1309px #000,
      1616px 408px #000,
      1833px 853px #000,
      1433px 1656px #000,
      811px 1861px #000,
      439px 1672px #000,
      1105px 248px #000,
      328px 1652px #000,
      13px 1658px #000,
      685px 987px #000,
      985px 403px #000,
      1664px 1206px #000,
      1993px 1925px #000,
      440px 917px #000,
      1835px 319px #000,
      1404px 1907px #000,
      624px 1443px #000,
      843px 954px #000,
      478px 1567px #000,
      895px 1602px #000,
      1231px 871px #000,
      1267px 1646px #000,
      475px 334px #000,
      784px 796px #000,
      1294px 199px #000,
      109px 702px #000,
      1978px 362px #000,
      291px 940px #000,
      971px 1343px #000,
      74px 719px #000,
      36px 715px #000,
      1007px 1423px #000,
      860px 314px #000,
      631px 177px #000,
      1900px 1590px #000,
      1239px 1348px #000,
      1346px 1270px #000,
      1934px 1475px #000,
      1553px 559px #000,
      588px 1969px #000,
      670px 1269px #000,
      1484px 376px #000,
      20px 1424px #000,
      1396px 8px #000,
      969px 244px #000,
      1807px 538px #000,
      1873px 891px #000,
      636px 1142px #000,
      1474px 1562px #000,
      763px 350px #000,
      663px 700px #000,
      500px 1469px #000,
      1302px 722px #000,
      181px 291px #000,
      266px 893px #000,
      1403px 654px #000,
      492px 460px #000,
      1503px 1369px #000,
      23px 1662px #000,
      349px 333px #000,
      1435px 1017px #000,
      1441px 705px #000,
      1708px 1446px #000,
      1041px 911px #000,
      1063px 780px #000,
      1158px 1356px #000,
      767px 1454px #000,
      1912px 797px #000,
      1731px 1759px #000,
      1378px 1390px #000,
      1815px 1364px #000,
      960px 270px #000,
      1343px 427px #000,
      275px 203px #000,
      1319px 1092px #000,
      1455px 770px #000,
      283px 1503px #000,
      1505px 901px #000,
      1738px 1561px #000,
      1526px 1935px #000,
      1757px 669px #000,
      1640px 620px #000,
      1750px 722px #000,
      748px 66px #000,
      1149px 540px #000,
      159px 953px #000,
      200px 1426px #000,
      515px 1110px #000,
      1552px 737px #000,
      1094px 1459px #000,
      778px 799px #000,
      1031px 523px #000,
      743px 1825px #000,
      1100px 882px #000,
      1088px 1836px #000,
      255px 599px #000,
      67px 1361px #000,
      247px 1721px #000,
      1722px 346px #000,
      1822px 155px #000,
      452px 1973px #000,
      415px 1960px #000,
      1109px 57px #000,
      273px 1392px #000,
      404px 1071px #000,
      1212px 353px #000,
      370px 460px #000,
      795px 1523px #000,
      1932px 340px #000,
      51px 1473px #000,
      1268px 364px #000,
      1512px 1862px #000,
      1678px 1801px #000,
      1796px 579px #000,
      254px 251px #000,
      1466px 1717px #000,
      893px 379px #000,
      1153px 923px #000,
      913px 1808px #000,
      791px 789px #000,
      417px 1924px #000,
      1336px 1599px #000,
      1695px 908px #000,
      1120px 114px #000,
      493px 1949px #000,
      68px 1905px #000,
      969px 481px #000,
      1420px 1095px #000,
      800px 1117px #000,
      390px 234px #000,
      356px 1644px #000,
      1098px 1486px #000,
      1360px 521px #000,
      149px 1198px #000,
      354px 747px #000,
      1749px 487px #000,
      470px 76px #000,
      1672px 289px #000,
      1731px 545px #000,
      1547px 1590px #000,
      498px 692px #000,
      398px 1592px #000,
      1846px 1237px #000,
      1537px 1474px #000,
      1726px 1374px #000,
      1922px 858px #000,
      376px 321px #000,
      985px 227px #000,
      234px 1421px #000,
      760px 745px #000,
      1990px 1132px #000,
      1560px 1597px #000,
      338px 1310px #000,
      1924px 1664px #000,
      547px 1747px #000,
      1639px 1282px #000,
      1202px 337px #000,
      1985px 779px #000,
      737px 456px #000,
      89px 501px #000,
      963px 792px #000,
      655px 1447px #000,
      1492px 1994px #000,
      1171px 254px #000,
      892px 827px #000,
      1735px 442px #000,
      1474px 1187px #000,
      846px 1518px #000,
      557px 1805px #000,
      738px 945px #000,
      795px 68px #000,
      663px 1956px #000,
      1607px 290px #000,
      1524px 15px #000,
      1097px 1911px #000,
      157px 1939px #000,
      935px 1065px #000,
      1809px 1708px #000,
      164px 1157px #000,
      83px 855px #000,
      625px 501px #000,
      814px 398px #000,
      552px 695px #000,
      597px 1546px #000,
      1237px 1417px #000,
      628px 284px #000,
      866px 767px #000,
      1403px 1394px #000,
      765px 1563px #000,
      1648px 109px #000,
      1205px 1659px #000,
      921px 1313px #000,
      1319px 243px #000,
      18px 125px #000,
      7px 777px #000,
      181px 418px #000,
      1062px 1892px #000,
      382px 106px #000,
      994px 751px #000,
      964px 234px #000,
      40px 118px #000,
      278px 706px #000,
      1540px 1978px #000,
      425px 1661px #000,
      1050px 321px #000,
      735px 1729px #000,
      1438px 260px #000,
      1229px 1109px #000,
      186px 1041px #000,
      244px 1184px #000,
      392px 1472px #000,
      670px 1249px #000,
      1260px 1443px #000,
      1977px 1511px #000,
      1240px 773px #000,
      303px 513px #000,
      63px 1530px #000,
      610px 792px #000,
      1987px 1647px #000,
      676px 1597px #000,
      1740px 1244px #000,
      816px 1661px #000,
      351px 802px #000,
      252px 1082px #000,
      31px 365px #000,
      1453px 984px #000,
      667px 1233px #000,
      1247px 1800px #000,
      839px 270px #000,
      775px 913px #000,
      1966px 1398px #000,
      499px 813px #000,
      922px 1982px #000,
      1409px 1902px #000,
      1499px 1766px #000,
      721px 899px #000,
      788px 807px #000,
      989px 1355px #000,
      1248px 1274px #000,
      849px 1091px #000,
      1799px 1036px #000,
      1486px 700px #000,
      170px 1989px #000,
      1275px 799px #000,
      772px 2000px #000,
      1642px 362px #000,
      216px 940px #000,
      1893px 281px #000,
      1944px 1298px #000,
      1294px 400px #000,
      1523px 441px #000,
      1829px 340px #000,
      468px 170px #000,
      1099px 967px #000,
      1331px 665px #000,
      1174px 1553px #000,
      1567px 325px #000,
      1028px 1399px #000,
      781px 1451px #000,
      1912px 1954px #000,
      874px 873px #000,
      1298px 1722px #000,
      1879px 706px #000,
      57px 1221px #000,
      1116px 1432px #000,
      48px 811px #000,
      101px 916px #000,
      677px 304px #000,
      1203px 639px #000,
      1391px 199px #000,
      1895px 1988px #000,
      1462px 1023px #000,
      1216px 1751px #000,
      1261px 663px #000,
      1290px 1119px #000,
      137px 1793px #000,
      1052px 1470px #000,
      1561px 226px #000,
      1156px 402px #000,
      709px 693px #000,
      1040px 1911px #000,
      1624px 1115px #000,
      551px 475px #000,
      416px 1090px #000,
      1183px 451px #000,
      58px 765px #000,
      743px 1016px #000,
      198px 369px #000,
      1645px 1503px #000,
      997px 22px #000,
      1447px 1323px #000,
      379px 883px #000,
      1171px 1195px #000,
      919px 133px #000,
      1400px 517px #000,
      725px 804px #000,
      1600px 699px #000,
      357px 581px #000,
      266px 1713px #000,
      848px 1749px #000,
      1963px 1045px #000,
      119px 1136px #000;
  }

  @media (prefers-color-scheme: dark) {
    box-shadow:
      117px 1613px #fff,
      1488px 635px #fff,
      944px 914px #fff,
      647px 277px #fff,
      1792px 1205px #fff,
      656px 1517px #fff,
      820px 1839px #fff,
      1153px 1400px #fff,
      870px 13px #fff,
      550px 702px #fff,
      1155px 1056px #fff,
      88px 1709px #fff,
      1450px 1090px #fff,
      1929px 457px #fff,
      1390px 905px #fff,
      1771px 269px #fff,
      1741px 669px #fff,
      432px 64px #fff,
      563px 996px #fff,
      1918px 1873px #fff,
      1845px 1211px #fff,
      231px 1503px #fff,
      37px 220px #fff,
      1970px 495px #fff,
      1812px 925px #fff,
      67px 1398px #fff,
      535px 279px #fff,
      1837px 829px #fff,
      1945px 685px #fff,
      1677px 1817px #fff,
      1317px 1415px #fff,
      1785px 905px #fff,
      1787px 1554px #fff,
      802px 1296px #fff,
      512px 1101px #fff,
      583px 1364px #fff,
      336px 558px #fff,
      979px 334px #fff,
      106px 792px #fff,
      204px 34px #fff,
      1845px 1763px #fff,
      445px 1599px #fff,
      386px 453px #fff,
      471px 952px #fff,
      1466px 1676px #fff,
      1885px 303px #fff,
      51px 1717px #fff,
      1211px 299px #fff,
      1546px 1887px #fff,
      1067px 33px #fff,
      1088px 1326px #fff,
      1938px 760px #fff,
      470px 648px #fff,
      1213px 269px #fff,
      1767px 78px #fff,
      977px 976px #fff,
      1926px 175px #fff,
      722px 1512px #fff,
      945px 227px #fff,
      1811px 99px #fff,
      1912px 1406px #fff,
      1602px 1243px #fff,
      610px 449px #fff,
      654px 1393px #fff,
      1930px 1193px #fff,
      258px 1184px #fff,
      89px 265px #fff,
      824px 1494px #fff,
      1506px 1435px #fff,
      1027px 753px #fff,
      1px 1197px #fff,
      530px 1161px #fff,
      864px 1555px #fff,
      1610px 1604px #fff,
      1035px 1114px #fff,
      1456px 133px #fff,
      1196px 1253px #fff,
      361px 1037px #fff,
      834px 351px #fff,
      436px 1676px #fff,
      1194px 1007px #fff,
      1141px 647px #fff,
      319px 454px #fff,
      937px 1769px #fff,
      1872px 1013px #fff,
      733px 643px #fff,
      1250px 511px #fff,
      189px 296px #fff,
      1639px 163px #fff,
      1584px 336px #fff,
      1912px 1343px #fff,
      1298px 1307px #fff,
      1750px 902px #fff,
      1129px 845px #fff,
      1899px 1470px #fff,
      1427px 232px #fff,
      1391px 838px #fff,
      1225px 1819px #fff,
      190px 1366px #fff,
      1865px 518px #fff,
      203px 1383px #fff,
      1455px 614px #fff,
      423px 354px #fff,
      1678px 1790px #fff,
      241px 608px #fff,
      1089px 730px #fff,
      1342px 38px #fff,
      1848px 249px #fff,
      1874px 1785px #fff,
      1040px 1837px #fff,
      751px 261px #fff,
      510px 1975px #fff,
      52px 795px #fff,
      1786px 1310px #fff,
      498px 712px #fff,
      190px 375px #fff,
      1341px 722px #fff,
      43px 1394px #fff,
      1821px 1687px #fff,
      106px 130px #fff,
      1717px 1978px #fff,
      168px 151px #fff,
      183px 740px #fff,
      945px 1381px #fff,
      669px 1170px #fff,
      1285px 1816px #fff,
      110px 1217px #fff,
      1623px 813px #fff,
      869px 647px #fff,
      867px 582px #fff,
      735px 1240px #fff,
      519px 1896px #fff,
      132px 156px #fff,
      1649px 193px #fff,
      241px 1109px #fff,
      643px 484px #fff,
      574px 1282px #fff,
      1952px 564px #fff,
      1978px 145px #fff,
      329px 903px #fff,
      1674px 617px #fff,
      1978px 558px #fff,
      1808px 1715px #fff,
      1526px 1238px #fff,
      475px 1330px #fff,
      810px 425px #fff,
      1709px 634px #fff,
      1658px 336px #fff,
      425px 194px #fff,
      352px 96px #fff,
      148px 180px #fff,
      1139px 1046px #fff,
      1809px 1233px #fff,
      1669px 171px #fff,
      263px 1394px #fff,
      534px 715px #fff,
      396px 1008px #fff,
      589px 1445px #fff,
      1190px 381px #fff,
      1709px 279px #fff,
      520px 891px #fff,
      1136px 1867px #fff,
      1280px 1233px #fff,
      836px 296px #fff,
      1348px 646px #fff,
      1539px 913px #fff,
      423px 781px #fff,
      1271px 1805px #fff,
      696px 564px #fff,
      1549px 804px #fff,
      303px 1555px #fff,
      1449px 1903px #fff,
      66px 687px #fff,
      1164px 856px #fff,
      1958px 1326px #fff,
      125px 157px #fff,
      508px 1669px #fff,
      465px 725px #fff,
      1925px 1440px #fff,
      405px 793px #fff,
      278px 110px #fff,
      1084px 1065px #fff,
      1077px 705px #fff,
      663px 1844px #fff,
      734px 263px #fff,
      870px 1761px #fff,
      103px 1169px #fff,
      1506px 1295px #fff,
      1883px 926px #fff,
      335px 1361px #fff,
      1126px 1284px #fff,
      257px 1165px #fff,
      837px 580px #fff,
      1211px 1362px #fff,
      1137px 1380px #fff,
      135px 632px #fff,
      1491px 1965px #fff,
      1098px 195px #fff,
      506px 417px #fff,
      693px 1243px #fff,
      622px 1862px #fff,
      1412px 1343px #fff,
      948px 1894px #fff,
      1315px 1363px #fff,
      754px 1098px #fff,
      1931px 930px #fff,
      1831px 342px #fff,
      1751px 1839px #fff,
      84px 775px #fff,
      1662px 1488px #fff,
      617px 1769px #fff,
      1869px 1292px #fff,
      963px 432px #fff,
      371px 1114px #fff,
      37px 642px #fff,
      21px 1184px #fff,
      602px 366px #fff,
      414px 524px #fff,
      282px 244px #fff,
      1689px 868px #fff,
      943px 681px #fff,
      898px 679px #fff,
      449px 1774px #fff,
      1678px 1313px #fff,
      475px 1811px #fff,
      1146px 1509px #fff,
      1151px 1863px #fff,
      1617px 846px #fff,
      82px 1077px #fff,
      324px 1317px #fff,
      1516px 885px #fff,
      1706px 1526px #fff,
      1925px 1180px #fff,
      553px 967px #fff,
      1072px 536px #fff,
      1715px 1816px #fff,
      185px 286px #fff,
      1362px 1600px #fff,
      628px 1938px #fff,
      1187px 412px #fff,
      569px 211px #fff,
      1959px 1356px #fff,
      1571px 105px #fff,
      319px 1111px #fff,
      36px 1364px #fff,
      502px 1788px #fff,
      1051px 1993px #fff,
      1617px 773px #fff,
      424px 1507px #fff,
      1623px 1955px #fff,
      307px 662px #fff,
      183px 1048px #fff,
      1919px 1453px #fff,
      1006px 1817px #fff,
      468px 673px #fff,
      1142px 1375px #fff,
      1228px 443px #fff,
      1734px 552px #fff,
      20px 1041px #fff,
      1783px 334px #fff,
      98px 1237px #fff,
      1356px 1940px #fff,
      853px 1779px #fff,
      1910px 560px #fff,
      1174px 1656px #fff,
      110px 1724px #fff,
      542px 1771px #fff,
      1758px 1931px #fff,
      1463px 1401px #fff,
      1155px 84px #fff,
      1504px 835px #fff,
      750px 322px #fff,
      407px 1900px #fff,
      1600px 1141px #fff,
      657px 886px #fff,
      526px 714px #fff,
      18px 836px #fff,
      1546px 1548px #fff,
      22px 469px #fff,
      594px 1466px #fff,
      1160px 1078px #fff,
      627px 1055px #fff,
      195px 699px #fff,
      1099px 684px #fff,
      530px 551px #fff,
      1160px 1325px #fff,
      894px 727px #fff,
      1157px 98px #fff,
      136px 1483px #fff,
      1875px 1975px #fff,
      1803px 566px #fff,
      318px 1073px #fff,
      1866px 1656px #fff,
      543px 414px #fff,
      719px 474px #fff,
      1115px 738px #fff,
      353px 875px #fff,
      184px 1938px #fff,
      1854px 1534px #fff,
      420px 1698px #fff,
      1480px 1550px #fff,
      522px 203px #fff,
      1897px 1904px #fff,
      975px 1708px #fff,
      1774px 602px #fff,
      1908px 274px #fff,
      61px 715px #fff,
      983px 1156px #fff,
      326px 1013px #fff,
      641px 290px #fff,
      1522px 120px #fff,
      405px 1637px #fff,
      1021px 1099px #fff,
      631px 1145px #fff,
      982px 1967px #fff,
      200px 651px #fff,
      795px 351px #fff,
      790px 1082px #fff,
      144px 1572px #fff,
      1542px 901px #fff,
      158px 1524px #fff,
      849px 1843px #fff,
      1807px 203px #fff,
      1747px 45px #fff,
      1603px 1738px #fff,
      617px 1966px #fff,
      342px 748px #fff,
      1779px 1173px #fff,
      1428px 152px #fff,
      589px 1998px #fff,
      1940px 1838px #fff,
      115px 272px #fff,
      1217px 1395px #fff,
      1402px 1491px #fff,
      1833px 1814px #fff,
      243px 966px #fff,
      319px 578px #fff,
      813px 364px #fff,
      669px 882px #fff,
      551px 134px #fff,
      1819px 920px #fff,
      740px 1826px #fff,
      1021px 952px #fff,
      1575px 453px #fff,
      324px 419px #fff,
      929px 417px #fff,
      885px 1112px #fff,
      503px 187px #fff,
      1908px 362px #fff,
      1063px 1601px #fff,
      169px 1792px #fff,
      789px 963px #fff,
      1697px 948px #fff,
      1761px 1810px #fff,
      1844px 1591px #fff,
      1709px 949px #fff,
      1402px 1396px #fff,
      1037px 225px #fff,
      1832px 518px #fff,
      1728px 1782px #fff,
      194px 1421px #fff,
      1395px 742px #fff,
      1478px 1325px #fff,
      40px 593px #fff,
      1732px 117px #fff,
      51px 158px #fff,
      1598px 1672px #fff,
      701px 849px #fff,
      1403px 1979px #fff,
      145px 1414px #fff,
      550px 906px #fff,
      1366px 460px #fff,
      142px 1379px #fff,
      34px 1864px #fff,
      1346px 308px #fff,
      293px 998px #fff,
      21px 1868px #fff,
      540px 1033px #fff,
      60px 746px #fff,
      1602px 1476px #fff,
      180px 804px #fff,
      345px 1982px #fff,
      1439px 640px #fff,
      939px 1834px #fff,
      20px 432px #fff,
      492px 1549px #fff,
      109px 1579px #fff,
      1796px 1403px #fff,
      1079px 519px #fff,
      1664px 389px #fff,
      1627px 1061px #fff,
      823px 419px #fff,
      1399px 1882px #fff,
      1906px 344px #fff,
      1189px 848px #fff,
      117px 882px #fff,
      1262px 33px #fff,
      1048px 434px #fff,
      1208px 1309px #fff,
      1616px 408px #fff,
      1833px 853px #fff,
      1433px 1656px #fff,
      811px 1861px #fff,
      439px 1672px #fff,
      1105px 248px #fff,
      328px 1652px #fff,
      13px 1658px #fff,
      685px 987px #fff,
      985px 403px #fff,
      1664px 1206px #fff,
      1993px 1925px #fff,
      440px 917px #fff,
      1835px 319px #fff,
      1404px 1907px #fff,
      624px 1443px #fff,
      843px 954px #fff,
      478px 1567px #fff,
      895px 1602px #fff,
      1231px 871px #fff,
      1267px 1646px #fff,
      475px 334px #fff,
      784px 796px #fff,
      1294px 199px #fff,
      109px 702px #fff,
      1978px 362px #fff,
      291px 940px #fff,
      971px 1343px #fff,
      74px 719px #fff,
      36px 715px #fff,
      1007px 1423px #fff,
      860px 314px #fff,
      631px 177px #fff,
      1900px 1590px #fff,
      1239px 1348px #fff,
      1346px 1270px #fff,
      1934px 1475px #fff,
      1553px 559px #fff,
      588px 1969px #fff,
      670px 1269px #fff,
      1484px 376px #fff,
      20px 1424px #fff,
      1396px 8px #fff,
      969px 244px #fff,
      1807px 538px #fff,
      1873px 891px #fff,
      636px 1142px #fff,
      1474px 1562px #fff,
      763px 350px #fff,
      663px 700px #fff,
      500px 1469px #fff,
      1302px 722px #fff,
      181px 291px #fff,
      266px 893px #fff,
      1403px 654px #fff,
      492px 460px #fff,
      1503px 1369px #fff,
      23px 1662px #fff,
      349px 333px #fff,
      1435px 1017px #fff,
      1441px 705px #fff,
      1708px 1446px #fff,
      1041px 911px #fff,
      1063px 780px #fff,
      1158px 1356px #fff,
      767px 1454px #fff,
      1912px 797px #fff,
      1731px 1759px #fff,
      1378px 1390px #fff,
      1815px 1364px #fff,
      960px 270px #fff,
      1343px 427px #fff,
      275px 203px #fff,
      1319px 1092px #fff,
      1455px 770px #fff,
      283px 1503px #fff,
      1505px 901px #fff,
      1738px 1561px #fff,
      1526px 1935px #fff,
      1757px 669px #fff,
      1640px 620px #fff,
      1750px 722px #fff,
      748px 66px #fff,
      1149px 540px #fff,
      159px 953px #fff,
      200px 1426px #fff,
      515px 1110px #fff,
      1552px 737px #fff,
      1094px 1459px #fff,
      778px 799px #fff,
      1031px 523px #fff,
      743px 1825px #fff,
      1100px 882px #fff,
      1088px 1836px #fff,
      255px 599px #fff,
      67px 1361px #fff,
      247px 1721px #fff,
      1722px 346px #fff,
      1822px 155px #fff,
      452px 1973px #fff,
      415px 1960px #fff,
      1109px 57px #fff,
      273px 1392px #fff,
      404px 1071px #fff,
      1212px 353px #fff,
      370px 460px #fff,
      795px 1523px #fff,
      1932px 340px #fff,
      51px 1473px #fff,
      1268px 364px #fff,
      1512px 1862px #fff,
      1678px 1801px #fff,
      1796px 579px #fff,
      254px 251px #fff,
      1466px 1717px #fff,
      893px 379px #fff,
      1153px 923px #fff,
      913px 1808px #fff,
      791px 789px #fff,
      417px 1924px #fff,
      1336px 1599px #fff,
      1695px 908px #fff,
      1120px 114px #fff,
      493px 1949px #fff,
      68px 1905px #fff,
      969px 481px #fff,
      1420px 1095px #fff,
      800px 1117px #fff,
      390px 234px #fff,
      356px 1644px #fff,
      1098px 1486px #fff,
      1360px 521px #fff,
      149px 1198px #fff,
      354px 747px #fff,
      1749px 487px #fff,
      470px 76px #fff,
      1672px 289px #fff,
      1731px 545px #fff,
      1547px 1590px #fff,
      498px 692px #fff,
      398px 1592px #fff,
      1846px 1237px #fff,
      1537px 1474px #fff,
      1726px 1374px #fff,
      1922px 858px #fff,
      376px 321px #fff,
      985px 227px #fff,
      234px 1421px #fff,
      760px 745px #fff,
      1990px 1132px #fff,
      1560px 1597px #fff,
      338px 1310px #fff,
      1924px 1664px #fff,
      547px 1747px #fff,
      1639px 1282px #fff,
      1202px 337px #fff,
      1985px 779px #fff,
      737px 456px #fff,
      89px 501px #fff,
      963px 792px #fff,
      655px 1447px #fff,
      1492px 1994px #fff,
      1171px 254px #fff,
      892px 827px #fff,
      1735px 442px #fff,
      1474px 1187px #fff,
      846px 1518px #fff,
      557px 1805px #fff,
      738px 945px #fff,
      795px 68px #fff,
      663px 1956px #fff,
      1607px 290px #fff,
      1524px 15px #fff,
      1097px 1911px #fff,
      157px 1939px #fff,
      935px 1065px #fff,
      1809px 1708px #fff,
      164px 1157px #fff,
      83px 855px #fff,
      625px 501px #fff,
      814px 398px #fff,
      552px 695px #fff,
      597px 1546px #fff,
      1237px 1417px #fff,
      628px 284px #fff,
      866px 767px #fff,
      1403px 1394px #fff,
      765px 1563px #fff,
      1648px 109px #fff,
      1205px 1659px #fff,
      921px 1313px #fff,
      1319px 243px #fff,
      18px 125px #fff,
      7px 777px #fff,
      181px 418px #fff,
      1062px 1892px #fff,
      382px 106px #fff,
      994px 751px #fff,
      964px 234px #fff,
      40px 118px #fff,
      278px 706px #fff,
      1540px 1978px #fff,
      425px 1661px #fff,
      1050px 321px #fff,
      735px 1729px #fff,
      1438px 260px #fff,
      1229px 1109px #fff,
      186px 1041px #fff,
      244px 1184px #fff,
      392px 1472px #fff,
      670px 1249px #fff,
      1260px 1443px #fff,
      1977px 1511px #fff,
      1240px 773px #fff,
      303px 513px #fff,
      63px 1530px #fff,
      610px 792px #fff,
      1987px 1647px #fff,
      676px 1597px #fff,
      1740px 1244px #fff,
      816px 1661px #fff,
      351px 802px #fff,
      252px 1082px #fff,
      31px 365px #fff,
      1453px 984px #fff,
      667px 1233px #fff,
      1247px 1800px #fff,
      839px 270px #fff,
      775px 913px #fff,
      1966px 1398px #fff,
      499px 813px #fff,
      922px 1982px #fff,
      1409px 1902px #fff,
      1499px 1766px #fff,
      721px 899px #fff,
      788px 807px #fff,
      989px 1355px #fff,
      1248px 1274px #fff,
      849px 1091px #fff,
      1799px 1036px #fff,
      1486px 700px #fff,
      170px 1989px #fff,
      1275px 799px #fff,
      772px 2000px #fff,
      1642px 362px #fff,
      216px 940px #fff,
      1893px 281px #fff,
      1944px 1298px #fff,
      1294px 400px #fff,
      1523px 441px #fff,
      1829px 340px #fff,
      468px 170px #fff,
      1099px 967px #fff,
      1331px 665px #fff,
      1174px 1553px #fff,
      1567px 325px #fff,
      1028px 1399px #fff,
      781px 1451px #fff,
      1912px 1954px #fff,
      874px 873px #fff,
      1298px 1722px #fff,
      1879px 706px #fff,
      57px 1221px #fff,
      1116px 1432px #fff,
      48px 811px #fff,
      101px 916px #fff,
      677px 304px #fff,
      1203px 639px #fff,
      1391px 199px #fff,
      1895px 1988px #fff,
      1462px 1023px #fff,
      1216px 1751px #fff,
      1261px 663px #fff,
      1290px 1119px #fff,
      137px 1793px #fff,
      1052px 1470px #fff,
      1561px 226px #fff,
      1156px 402px #fff,
      709px 693px #fff,
      1040px 1911px #fff,
      1624px 1115px #fff,
      551px 475px #fff,
      416px 1090px #fff,
      1183px 451px #fff,
      58px 765px #fff,
      743px 1016px #fff,
      198px 369px #fff,
      1645px 1503px #fff,
      997px 22px #fff,
      1447px 1323px #fff,
      379px 883px #fff,
      1171px 1195px #fff,
      919px 133px #fff,
      1400px 517px #fff,
      725px 804px #fff,
      1600px 699px #fff,
      357px 581px #fff,
      266px 1713px #fff,
      848px 1749px #fff,
      1963px 1045px #fff,
      119px 1136px #fff;
  }
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;

  @media (prefers-color-scheme: light) {
    box-shadow:
      1117px 1306px #000,
      1078px 1783px #000,
      1179px 1085px #000,
      1145px 920px #000,
      422px 1233px #000,
      387px 98px #000,
      1153px 637px #000,
      1084px 782px #000,
      476px 453px #000,
      926px 1306px #000,
      60px 1086px #000,
      753px 1575px #000,
      272px 1684px #000,
      1285px 750px #000,
      1416px 1327px #000,
      1931px 473px #000,
      736px 1395px #000,
      1816px 763px #000,
      438px 879px #000,
      665px 1902px #000,
      1341px 677px #000,
      1404px 1073px #000,
      100px 597px #000,
      357px 1689px #000,
      1044px 1342px #000,
      1954px 502px #000,
      1192px 1308px #000,
      540px 1239px #000,
      1360px 552px #000,
      89px 752px #000,
      659px 1253px #000,
      62px 517px #000,
      1375px 1705px #000,
      1343px 1511px #000,
      1659px 1922px #000,
      1560px 289px #000,
      1362px 1799px #000,
      1886px 1480px #000,
      1718px 1885px #000,
      824px 738px #000,
      1060px 1370px #000,
      1781px 1171px #000,
      255px 273px #000,
      1197px 120px #000,
      213px 7px #000,
      1226px 1920px #000,
      1844px 207px #000,
      1675px 970px #000,
      1435px 1283px #000,
      37px 353px #000,
      59px 417px #000,
      921px 1602px #000,
      1549px 1490px #000,
      638px 1845px #000,
      1328px 198px #000,
      1050px 1149px #000,
      1884px 711px #000,
      333px 263px #000,
      342px 1508px #000,
      1388px 1810px #000,
      1377px 1558px #000,
      890px 487px #000,
      1081px 759px #000,
      890px 1515px #000,
      911px 1284px #000,
      335px 735px #000,
      1140px 549px #000,
      1239px 1064px #000,
      226px 71px #000,
      1100px 1278px #000,
      1851px 1805px #000,
      1370px 1999px #000,
      1008px 1122px #000,
      785px 813px #000,
      1358px 601px #000,
      1833px 1305px #000,
      1768px 1304px #000,
      1303px 532px #000,
      860px 598px #000,
      1329px 593px #000,
      1038px 1088px #000,
      408px 405px #000,
      965px 82px #000,
      1483px 1438px #000,
      310px 1479px #000,
      1786px 1500px #000,
      1866px 852px #000,
      18px 1757px #000,
      1473px 1004px #000,
      1542px 1933px #000,
      633px 1970px #000,
      1334px 1713px #000,
      175px 28px #000,
      592px 894px #000,
      121px 1162px #000,
      1601px 1567px #000,
      1095px 657px #000,
      640px 1233px #000,
      1073px 1255px #000,
      840px 1087px #000,
      718px 250px #000,
      967px 709px #000,
      731px 239px #000,
      1623px 593px #000,
      1058px 1820px #000,
      516px 1898px #000,
      666px 12px #000,
      1997px 1382px #000,
      112px 1690px #000,
      687px 1309px #000,
      63px 539px #000,
      185px 1897px #000,
      1055px 1691px #000,
      435px 1517px #000,
      1175px 1119px #000,
      1721px 133px #000,
      1212px 47px #000,
      166px 18px #000,
      1416px 1652px #000,
      1409px 1745px #000,
      1357px 1232px #000,
      1677px 1998px #000,
      448px 1415px #000,
      705px 1736px #000,
      1031px 1466px #000,
      543px 1651px #000,
      1592px 1888px #000,
      1749px 1175px #000,
      639px 1114px #000,
      1591px 508px #000,
      759px 1244px #000,
      824px 380px #000,
      942px 955px #000,
      723px 732px #000,
      113px 1369px #000,
      203px 1739px #000,
      868px 733px #000,
      713px 971px #000,
      341px 833px #000,
      762px 824px #000,
      1359px 310px #000,
      1858px 1349px #000,
      1531px 692px #000,
      1075px 1512px #000,
      1677px 142px #000,
      1912px 1478px #000,
      1810px 1078px #000,
      426px 844px #000,
      1426px 588px #000,
      1909px 654px #000,
      1107px 295px #000,
      1351px 527px #000,
      1393px 599px #000,
      1379px 1068px #000,
      228px 1846px #000,
      1271px 374px #000,
      1348px 612px #000,
      7px 1301px #000,
      1501px 1782px #000,
      1795px 423px #000,
      1475px 1918px #000,
      1328px 1861px #000,
      1624px 51px #000,
      1791px 672px #000,
      1594px 1467px #000,
      1655px 1603px #000,
      919px 850px #000,
      523px 609px #000,
      1196px 207px #000,
      753px 410px #000,
      686px 1097px #000,
      1570px 133px #000,
      1996px 1137px #000,
      361px 116px #000,
      1015px 462px #000,
      76px 1143px #000,
      491px 1818px #000,
      1563px 795px #000,
      982px 1721px #000,
      831px 1204px #000,
      1737px 589px #000,
      861px 1579px #000,
      1666px 130px #000,
      698px 1799px #000,
      726px 1519px #000,
      109px 1208px #000,
      1184px 1057px #000,
      835px 451px #000,
      896px 594px #000,
      35px 893px #000,
      895px 542px #000,
      706px 225px #000,
      56px 1040px #000,
      1954px 108px #000,
      1439px 1423px #000,
      26px 1881px #000,
      802px 1564px #000,
      273px 708px #000,
      40px 31px #000,
      859px 108px #000;
  }

  @media (prefers-color-scheme: dark) {
    box-shadow:
      1117px 1306px #fff,
      1078px 1783px #fff,
      1179px 1085px #fff,
      1145px 920px #fff,
      422px 1233px #fff,
      387px 98px #fff,
      1153px 637px #fff,
      1084px 782px #fff,
      476px 453px #fff,
      926px 1306px #fff,
      60px 1086px #fff,
      753px 1575px #fff,
      272px 1684px #fff,
      1285px 750px #fff,
      1416px 1327px #fff,
      1931px 473px #fff,
      736px 1395px #fff,
      1816px 763px #fff,
      438px 879px #fff,
      665px 1902px #fff,
      1341px 677px #fff,
      1404px 1073px #fff,
      100px 597px #fff,
      357px 1689px #fff,
      1044px 1342px #fff,
      1954px 502px #fff,
      1192px 1308px #fff,
      540px 1239px #fff,
      1360px 552px #fff,
      89px 752px #fff,
      659px 1253px #fff,
      62px 517px #fff,
      1375px 1705px #fff,
      1343px 1511px #fff,
      1659px 1922px #fff,
      1560px 289px #fff,
      1362px 1799px #fff,
      1886px 1480px #fff,
      1718px 1885px #fff,
      824px 738px #fff,
      1060px 1370px #fff,
      1781px 1171px #fff,
      255px 273px #fff,
      1197px 120px #fff,
      213px 7px #fff,
      1226px 1920px #fff,
      1844px 207px #fff,
      1675px 970px #fff,
      1435px 1283px #fff,
      37px 353px #fff,
      59px 417px #fff,
      921px 1602px #fff,
      1549px 1490px #fff,
      638px 1845px #fff,
      1328px 198px #fff,
      1050px 1149px #fff,
      1884px 711px #fff,
      333px 263px #fff,
      342px 1508px #fff,
      1388px 1810px #fff,
      1377px 1558px #fff,
      890px 487px #fff,
      1081px 759px #fff,
      890px 1515px #fff,
      911px 1284px #fff,
      335px 735px #fff,
      1140px 549px #fff,
      1239px 1064px #fff,
      226px 71px #fff,
      1100px 1278px #fff,
      1851px 1805px #fff,
      1370px 1999px #fff,
      1008px 1122px #fff,
      785px 813px #fff,
      1358px 601px #fff,
      1833px 1305px #fff,
      1768px 1304px #fff,
      1303px 532px #fff,
      860px 598px #fff,
      1329px 593px #fff,
      1038px 1088px #fff,
      408px 405px #fff,
      965px 82px #fff,
      1483px 1438px #fff,
      310px 1479px #fff,
      1786px 1500px #fff,
      1866px 852px #fff,
      18px 1757px #fff,
      1473px 1004px #fff,
      1542px 1933px #fff,
      633px 1970px #fff,
      1334px 1713px #fff,
      175px 28px #fff,
      592px 894px #fff,
      121px 1162px #fff,
      1601px 1567px #fff,
      1095px 657px #fff,
      640px 1233px #fff,
      1073px 1255px #fff,
      840px 1087px #fff,
      718px 250px #fff,
      967px 709px #fff,
      731px 239px #fff,
      1623px 593px #fff,
      1058px 1820px #fff,
      516px 1898px #fff,
      666px 12px #fff,
      1997px 1382px #fff,
      112px 1690px #fff,
      687px 1309px #fff,
      63px 539px #fff,
      185px 1897px #fff,
      1055px 1691px #fff,
      435px 1517px #fff,
      1175px 1119px #fff,
      1721px 133px #fff,
      1212px 47px #fff,
      166px 18px #fff,
      1416px 1652px #fff,
      1409px 1745px #fff,
      1357px 1232px #fff,
      1677px 1998px #fff,
      448px 1415px #fff,
      705px 1736px #fff,
      1031px 1466px #fff,
      543px 1651px #fff,
      1592px 1888px #fff,
      1749px 1175px #fff,
      639px 1114px #fff,
      1591px 508px #fff,
      759px 1244px #fff,
      824px 380px #fff,
      942px 955px #fff,
      723px 732px #fff,
      113px 1369px #fff,
      203px 1739px #fff,
      868px 733px #fff,
      713px 971px #fff,
      341px 833px #fff,
      762px 824px #fff,
      1359px 310px #fff,
      1858px 1349px #fff,
      1531px 692px #fff,
      1075px 1512px #fff,
      1677px 142px #fff,
      1912px 1478px #fff,
      1810px 1078px #fff,
      426px 844px #fff,
      1426px 588px #fff,
      1909px 654px #fff,
      1107px 295px #fff,
      1351px 527px #fff,
      1393px 599px #fff,
      1379px 1068px #fff,
      228px 1846px #fff,
      1271px 374px #fff,
      1348px 612px #fff,
      7px 1301px #fff,
      1501px 1782px #fff,
      1795px 423px #fff,
      1475px 1918px #fff,
      1328px 1861px #fff,
      1624px 51px #fff,
      1791px 672px #fff,
      1594px 1467px #fff,
      1655px 1603px #fff,
      919px 850px #fff,
      523px 609px #fff,
      1196px 207px #fff,
      753px 410px #fff,
      686px 1097px #fff,
      1570px 133px #fff,
      1996px 1137px #fff,
      361px 116px #fff,
      1015px 462px #fff,
      76px 1143px #fff,
      491px 1818px #fff,
      1563px 795px #fff,
      982px 1721px #fff,
      831px 1204px #fff,
      1737px 589px #fff,
      861px 1579px #fff,
      1666px 130px #fff,
      698px 1799px #fff,
      726px 1519px #fff,
      109px 1208px #fff,
      1184px 1057px #fff,
      835px 451px #fff,
      896px 594px #fff,
      35px 893px #fff,
      895px 542px #fff,
      706px 225px #fff,
      56px 1040px #fff,
      1954px 108px #fff,
      1439px 1423px #fff,
      26px 1881px #fff,
      802px 1564px #fff,
      273px 708px #fff,
      40px 31px #fff,
      859px 108px #fff;
  }

  -webkit-animation: animStar 42s linear infinite;
  -moz-animation: animStar 42s linear infinite;
  -ms-animation: animStar 42s linear infinite;
  animation: animStar 42s linear infinite;
}

#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;

  @media (prefers-color-scheme: light) {
    box-shadow:
      1117px 1306px #000,
      1078px 1783px #000,
      1179px 1085px #000,
      1145px 920px #000,
      422px 1233px #000,
      387px 98px #000,
      1153px 637px #000,
      1084px 782px #000,
      476px 453px #000,
      926px 1306px #000,
      60px 1086px #000,
      753px 1575px #000,
      272px 1684px #000,
      1285px 750px #000,
      1416px 1327px #000,
      1931px 473px #000,
      736px 1395px #000,
      1816px 763px #000,
      438px 879px #000,
      665px 1902px #000,
      1341px 677px #000,
      1404px 1073px #000,
      100px 597px #000,
      357px 1689px #000,
      1044px 1342px #000,
      1954px 502px #000,
      1192px 1308px #000,
      540px 1239px #000,
      1360px 552px #000,
      89px 752px #000,
      659px 1253px #000,
      62px 517px #000,
      1375px 1705px #000,
      1343px 1511px #000,
      1659px 1922px #000,
      1560px 289px #000,
      1362px 1799px #000,
      1886px 1480px #000,
      1718px 1885px #000,
      824px 738px #000,
      1060px 1370px #000,
      1781px 1171px #000,
      255px 273px #000,
      1197px 120px #000,
      213px 7px #000,
      1226px 1920px #000,
      1844px 207px #000,
      1675px 970px #000,
      1435px 1283px #000,
      37px 353px #000,
      59px 417px #000,
      921px 1602px #000,
      1549px 1490px #000,
      638px 1845px #000,
      1328px 198px #000,
      1050px 1149px #000,
      1884px 711px #000,
      333px 263px #000,
      342px 1508px #000,
      1388px 1810px #000,
      1377px 1558px #000,
      890px 487px #000,
      1081px 759px #000,
      890px 1515px #000,
      911px 1284px #000,
      335px 735px #000,
      1140px 549px #000,
      1239px 1064px #000,
      226px 71px #000,
      1100px 1278px #000,
      1851px 1805px #000,
      1370px 1999px #000,
      1008px 1122px #000,
      785px 813px #000,
      1358px 601px #000,
      1833px 1305px #000,
      1768px 1304px #000,
      1303px 532px #000,
      860px 598px #000,
      1329px 593px #000,
      1038px 1088px #000,
      408px 405px #000,
      965px 82px #000,
      1483px 1438px #000,
      310px 1479px #000,
      1786px 1500px #000,
      1866px 852px #000,
      18px 1757px #000,
      1473px 1004px #000,
      1542px 1933px #000,
      633px 1970px #000,
      1334px 1713px #000,
      175px 28px #000,
      592px 894px #000,
      121px 1162px #000,
      1601px 1567px #000,
      1095px 657px #000,
      640px 1233px #000,
      1073px 1255px #000,
      840px 1087px #000,
      718px 250px #000,
      967px 709px #000,
      731px 239px #000,
      1623px 593px #000,
      1058px 1820px #000,
      516px 1898px #000,
      666px 12px #000,
      1997px 1382px #000,
      112px 1690px #000,
      687px 1309px #000,
      63px 539px #000,
      185px 1897px #000,
      1055px 1691px #000,
      435px 1517px #000,
      1175px 1119px #000,
      1721px 133px #000,
      1212px 47px #000,
      166px 18px #000,
      1416px 1652px #000,
      1409px 1745px #000,
      1357px 1232px #000,
      1677px 1998px #000,
      448px 1415px #000,
      705px 1736px #000,
      1031px 1466px #000,
      543px 1651px #000,
      1592px 1888px #000,
      1749px 1175px #000,
      639px 1114px #000,
      1591px 508px #000,
      759px 1244px #000,
      824px 380px #000,
      942px 955px #000,
      723px 732px #000,
      113px 1369px #000,
      203px 1739px #000,
      868px 733px #000,
      713px 971px #000,
      341px 833px #000,
      762px 824px #000,
      1359px 310px #000,
      1858px 1349px #000,
      1531px 692px #000,
      1075px 1512px #000,
      1677px 142px #000,
      1912px 1478px #000,
      1810px 1078px #000,
      426px 844px #000,
      1426px 588px #000,
      1909px 654px #000,
      1107px 295px #000,
      1351px 527px #000,
      1393px 599px #000,
      1379px 1068px #000,
      228px 1846px #000,
      1271px 374px #000,
      1348px 612px #000,
      7px 1301px #000,
      1501px 1782px #000,
      1795px 423px #000,
      1475px 1918px #000,
      1328px 1861px #000,
      1624px 51px #000,
      1791px 672px #000,
      1594px 1467px #000,
      1655px 1603px #000,
      919px 850px #000,
      523px 609px #000,
      1196px 207px #000,
      753px 410px #000,
      686px 1097px #000,
      1570px 133px #000,
      1996px 1137px #000,
      361px 116px #000,
      1015px 462px #000,
      76px 1143px #000,
      491px 1818px #000,
      1563px 795px #000,
      982px 1721px #000,
      831px 1204px #000,
      1737px 589px #000,
      861px 1579px #000,
      1666px 130px #000,
      698px 1799px #000,
      726px 1519px #000,
      109px 1208px #000,
      1184px 1057px #000,
      835px 451px #000,
      896px 594px #000,
      35px 893px #000,
      895px 542px #000,
      706px 225px #000,
      56px 1040px #000,
      1954px 108px #000,
      1439px 1423px #000,
      26px 1881px #000,
      802px 1564px #000,
      273px 708px #000,
      40px 31px #000,
      859px 108px #000;
  }

  @media (prefers-color-scheme: dark) {
    box-shadow:
      1117px 1306px #fff,
      1078px 1783px #fff,
      1179px 1085px #fff,
      1145px 920px #fff,
      422px 1233px #fff,
      387px 98px #fff,
      1153px 637px #fff,
      1084px 782px #fff,
      476px 453px #fff,
      926px 1306px #fff,
      60px 1086px #fff,
      753px 1575px #fff,
      272px 1684px #fff,
      1285px 750px #fff,
      1416px 1327px #fff,
      1931px 473px #fff,
      736px 1395px #fff,
      1816px 763px #fff,
      438px 879px #fff,
      665px 1902px #fff,
      1341px 677px #fff,
      1404px 1073px #fff,
      100px 597px #fff,
      357px 1689px #fff,
      1044px 1342px #fff,
      1954px 502px #fff,
      1192px 1308px #fff,
      540px 1239px #fff,
      1360px 552px #fff,
      89px 752px #fff,
      659px 1253px #fff,
      62px 517px #fff,
      1375px 1705px #fff,
      1343px 1511px #fff,
      1659px 1922px #fff,
      1560px 289px #fff,
      1362px 1799px #fff,
      1886px 1480px #fff,
      1718px 1885px #fff,
      824px 738px #fff,
      1060px 1370px #fff,
      1781px 1171px #fff,
      255px 273px #fff,
      1197px 120px #fff,
      213px 7px #fff,
      1226px 1920px #fff,
      1844px 207px #fff,
      1675px 970px #fff,
      1435px 1283px #fff,
      37px 353px #fff,
      59px 417px #fff,
      921px 1602px #fff,
      1549px 1490px #fff,
      638px 1845px #fff,
      1328px 198px #fff,
      1050px 1149px #fff,
      1884px 711px #fff,
      333px 263px #fff,
      342px 1508px #fff,
      1388px 1810px #fff,
      1377px 1558px #fff,
      890px 487px #fff,
      1081px 759px #fff,
      890px 1515px #fff,
      911px 1284px #fff,
      335px 735px #fff,
      1140px 549px #fff,
      1239px 1064px #fff,
      226px 71px #fff,
      1100px 1278px #fff,
      1851px 1805px #fff,
      1370px 1999px #fff,
      1008px 1122px #fff,
      785px 813px #fff,
      1358px 601px #fff,
      1833px 1305px #fff,
      1768px 1304px #fff,
      1303px 532px #fff,
      860px 598px #fff,
      1329px 593px #fff,
      1038px 1088px #fff,
      408px 405px #fff,
      965px 82px #fff,
      1483px 1438px #fff,
      310px 1479px #fff,
      1786px 1500px #fff,
      1866px 852px #fff,
      18px 1757px #fff,
      1473px 1004px #fff,
      1542px 1933px #fff,
      633px 1970px #fff,
      1334px 1713px #fff,
      175px 28px #fff,
      592px 894px #fff,
      121px 1162px #fff,
      1601px 1567px #fff,
      1095px 657px #fff,
      640px 1233px #fff,
      1073px 1255px #fff,
      840px 1087px #fff,
      718px 250px #fff,
      967px 709px #fff,
      731px 239px #fff,
      1623px 593px #fff,
      1058px 1820px #fff,
      516px 1898px #fff,
      666px 12px #fff,
      1997px 1382px #fff,
      112px 1690px #fff,
      687px 1309px #fff,
      63px 539px #fff,
      185px 1897px #fff,
      1055px 1691px #fff,
      435px 1517px #fff,
      1175px 1119px #fff,
      1721px 133px #fff,
      1212px 47px #fff,
      166px 18px #fff,
      1416px 1652px #fff,
      1409px 1745px #fff,
      1357px 1232px #fff,
      1677px 1998px #fff,
      448px 1415px #fff,
      705px 1736px #fff,
      1031px 1466px #fff,
      543px 1651px #fff,
      1592px 1888px #fff,
      1749px 1175px #fff,
      639px 1114px #fff,
      1591px 508px #fff,
      759px 1244px #fff,
      824px 380px #fff,
      942px 955px #fff,
      723px 732px #fff,
      113px 1369px #fff,
      203px 1739px #fff,
      868px 733px #fff,
      713px 971px #fff,
      341px 833px #fff,
      762px 824px #fff,
      1359px 310px #fff,
      1858px 1349px #fff,
      1531px 692px #fff,
      1075px 1512px #fff,
      1677px 142px #fff,
      1912px 1478px #fff,
      1810px 1078px #fff,
      426px 844px #fff,
      1426px 588px #fff,
      1909px 654px #fff,
      1107px 295px #fff,
      1351px 527px #fff,
      1393px 599px #fff,
      1379px 1068px #fff,
      228px 1846px #fff,
      1271px 374px #fff,
      1348px 612px #fff,
      7px 1301px #fff,
      1501px 1782px #fff,
      1795px 423px #fff,
      1475px 1918px #fff,
      1328px 1861px #fff,
      1624px 51px #fff,
      1791px 672px #fff,
      1594px 1467px #fff,
      1655px 1603px #fff,
      919px 850px #fff,
      523px 609px #fff,
      1196px 207px #fff,
      753px 410px #fff,
      686px 1097px #fff,
      1570px 133px #fff,
      1996px 1137px #fff,
      361px 116px #fff,
      1015px 462px #fff,
      76px 1143px #fff,
      491px 1818px #fff,
      1563px 795px #fff,
      982px 1721px #fff,
      831px 1204px #fff,
      1737px 589px #fff,
      861px 1579px #fff,
      1666px 130px #fff,
      698px 1799px #fff,
      726px 1519px #fff,
      109px 1208px #fff,
      1184px 1057px #fff,
      835px 451px #fff,
      896px 594px #fff,
      35px 893px #fff,
      895px 542px #fff,
      706px 225px #fff,
      56px 1040px #fff,
      1954px 108px #fff,
      1439px 1423px #fff,
      26px 1881px #fff,
      802px 1564px #fff,
      273px 708px #fff,
      40px 31px #fff,
      859px 108px #fff;
  }
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;

  @media (prefers-color-scheme: light) {
    box-shadow:
      940px 1360px #000,
      1071px 539px #000,
      1710px 1414px #000,
      836px 299px #000,
      1944px 1420px #000,
      253px 1449px #000,
      1257px 1250px #000,
      1588px 1830px #000,
      1077px 1204px #000,
      273px 1081px #000,
      1993px 766px #000,
      1808px 479px #000,
      917px 263px #000,
      663px 1820px #000,
      342px 1988px #000,
      727px 1250px #000,
      636px 1666px #000,
      692px 1112px #000,
      248px 1211px #000,
      1422px 1121px #000,
      881px 46px #000,
      1531px 1977px #000,
      1643px 1023px #000,
      684px 1071px #000,
      1142px 1873px #000,
      292px 1313px #000,
      256px 1237px #000,
      89px 912px #000,
      964px 1783px #000,
      877px 760px #000,
      1641px 1474px #000,
      1492px 24px #000,
      1776px 1642px #000,
      183px 602px #000,
      1998px 62px #000,
      1560px 367px #000,
      1333px 995px #000,
      704px 1815px #000,
      1809px 712px #000,
      1503px 288px #000,
      630px 556px #000,
      1715px 125px #000,
      353px 1878px #000,
      975px 333px #000,
      1740px 1409px #000,
      1341px 1871px #000,
      1279px 1064px #000,
      169px 874px #000,
      161px 528px #000,
      1671px 1669px #000,
      169px 632px #000,
      547px 1724px #000,
      1904px 110px #000,
      679px 1670px #000,
      196px 123px #000,
      786px 871px #000,
      1840px 324px #000,
      356px 967px #000,
      61px 549px #000,
      99px 677px #000,
      1719px 87px #000,
      1713px 1990px #000,
      1717px 1358px #000,
      108px 1187px #000,
      51px 869px #000,
      1461px 902px #000,
      1034px 891px #000,
      962px 1881px #000,
      1723px 595px #000,
      479px 901px #000,
      1546px 1823px #000,
      285px 1208px #000,
      1056px 347px #000,
      261px 988px #000,
      466px 990px #000,
      1657px 648px #000,
      1249px 933px #000,
      1552px 1555px #000,
      147px 62px #000,
      292px 1157px #000,
      1816px 423px #000,
      1714px 757px #000,
      1036px 961px #000,
      1955px 710px #000,
      1842px 516px #000,
      479px 1870px #000,
      1579px 1445px #000,
      1225px 1309px #000,
      1965px 566px #000,
      1575px 1072px #000,
      923px 329px #000,
      651px 1514px #000,
      865px 1100px #000,
      782px 1873px #000,
      115px 299px #000,
      14px 1668px #000,
      1666px 1817px #000,
      1096px 1068px #000,
      1462px 742px #000,
      1384px 1750px #000;
  }

  @media (prefers-color-scheme: dark) {
    box-shadow:
      940px 1360px #fff,
      1071px 539px #fff,
      1710px 1414px #fff,
      836px 299px #fff,
      1944px 1420px #fff,
      253px 1449px #fff,
      1257px 1250px #fff,
      1588px 1830px #fff,
      1077px 1204px #fff,
      273px 1081px #fff,
      1993px 766px #fff,
      1808px 479px #fff,
      917px 263px #fff,
      663px 1820px #fff,
      342px 1988px #fff,
      727px 1250px #fff,
      636px 1666px #fff,
      692px 1112px #fff,
      248px 1211px #fff,
      1422px 1121px #fff,
      881px 46px #fff,
      1531px 1977px #fff,
      1643px 1023px #fff,
      684px 1071px #fff,
      1142px 1873px #fff,
      292px 1313px #fff,
      256px 1237px #fff,
      89px 912px #fff,
      964px 1783px #fff,
      877px 760px #fff,
      1641px 1474px #fff,
      1492px 24px #fff,
      1776px 1642px #fff,
      183px 602px #fff,
      1998px 62px #fff,
      1560px 367px #fff,
      1333px 995px #fff,
      704px 1815px #fff,
      1809px 712px #fff,
      1503px 288px #fff,
      630px 556px #fff,
      1715px 125px #fff,
      353px 1878px #fff,
      975px 333px #fff,
      1740px 1409px #fff,
      1341px 1871px #fff,
      1279px 1064px #fff,
      169px 874px #fff,
      161px 528px #fff,
      1671px 1669px #fff,
      169px 632px #fff,
      547px 1724px #fff,
      1904px 110px #fff,
      679px 1670px #fff,
      196px 123px #fff,
      786px 871px #fff,
      1840px 324px #fff,
      356px 967px #fff,
      61px 549px #fff,
      99px 677px #fff,
      1719px 87px #fff,
      1713px 1990px #fff,
      1717px 1358px #fff,
      108px 1187px #fff,
      51px 869px #fff,
      1461px 902px #fff,
      1034px 891px #fff,
      962px 1881px #fff,
      1723px 595px #fff,
      479px 901px #fff,
      1546px 1823px #fff,
      285px 1208px #fff,
      1056px 347px #fff,
      261px 988px #fff,
      466px 990px #fff,
      1657px 648px #fff,
      1249px 933px #fff,
      1552px 1555px #fff,
      147px 62px #fff,
      292px 1157px #fff,
      1816px 423px #fff,
      1714px 757px #fff,
      1036px 961px #fff,
      1955px 710px #fff,
      1842px 516px #fff,
      479px 1870px #fff,
      1579px 1445px #fff,
      1225px 1309px #fff,
      1965px 566px #fff,
      1575px 1072px #fff,
      923px 329px #fff,
      651px 1514px #fff,
      865px 1100px #fff,
      782px 1873px #fff,
      115px 299px #fff,
      14px 1668px #fff,
      1666px 1817px #fff,
      1096px 1068px #fff,
      1462px 742px #fff,
      1384px 1750px #fff;
  }

  -webkit-animation: animStar 36s linear infinite;
  -moz-animation: animStar 36s linear infinite;
  -ms-animation: animStar 36s linear infinite;
  animation: animStar 36s linear infinite;
}

#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow:
    940px 1360px #000,
    1071px 539px #000,
    1710px 1414px #000,
    836px 299px #000,
    1944px 1420px #000,
    253px 1449px #000,
    1257px 1250px #000,
    1588px 1830px #000,
    1077px 1204px #000,
    273px 1081px #000,
    1993px 766px #000,
    1808px 479px #000,
    917px 263px #000,
    663px 1820px #000,
    342px 1988px #000,
    727px 1250px #000,
    636px 1666px #000,
    692px 1112px #000,
    248px 1211px #000,
    1422px 1121px #000,
    881px 46px #000,
    1531px 1977px #000,
    1643px 1023px #000,
    684px 1071px #000,
    1142px 1873px #000,
    292px 1313px #000,
    256px 1237px #000,
    89px 912px #000,
    964px 1783px #000,
    877px 760px #000,
    1641px 1474px #000,
    1492px 24px #000,
    1776px 1642px #000,
    183px 602px #000,
    1998px 62px #000,
    1560px 367px #000,
    1333px 995px #000,
    704px 1815px #000,
    1809px 712px #000,
    1503px 288px #000,
    630px 556px #000,
    1715px 125px #000,
    353px 1878px #000,
    975px 333px #000,
    1740px 1409px #000,
    1341px 1871px #000,
    1279px 1064px #000,
    169px 874px #000,
    161px 528px #000,
    1671px 1669px #000,
    169px 632px #000,
    547px 1724px #000,
    1904px 110px #000,
    679px 1670px #000,
    196px 123px #000,
    786px 871px #000,
    1840px 324px #000,
    356px 967px #000,
    61px 549px #000,
    99px 677px #000,
    1719px 87px #000,
    1713px 1990px #000,
    1717px 1358px #000,
    108px 1187px #000,
    51px 869px #000,
    1461px 902px #000,
    1034px 891px #000,
    962px 1881px #000,
    1723px 595px #000,
    479px 901px #000,
    1546px 1823px #000,
    285px 1208px #000,
    1056px 347px #000,
    261px 988px #000,
    466px 990px #000,
    1657px 648px #000,
    1249px 933px #000,
    1552px 1555px #000,
    147px 62px #000,
    292px 1157px #000,
    1816px 423px #000,
    1714px 757px #000,
    1036px 961px #000,
    1955px 710px #000,
    1842px 516px #000,
    479px 1870px #000,
    1579px 1445px #000,
    1225px 1309px #000,
    1965px 566px #000,
    1575px 1072px #000,
    923px 329px #000,
    651px 1514px #000,
    865px 1100px #000,
    782px 1873px #000,
    115px 299px #000,
    14px 1668px #000,
    1666px 1817px #000,
    1096px 1068px #000,
    1462px 742px #000,
    1384px 1750px #000;

  @media (prefers-color-scheme: dark) {
    box-shadow:
      940px 1360px #fff,
      1071px 539px #fff,
      1710px 1414px #fff,
      836px 299px #fff,
      1944px 1420px #fff,
      253px 1449px #fff,
      1257px 1250px #fff,
      1588px 1830px #fff,
      1077px 1204px #fff,
      273px 1081px #fff,
      1993px 766px #fff,
      1808px 479px #fff,
      917px 263px #fff,
      663px 1820px #fff,
      342px 1988px #fff,
      727px 1250px #fff,
      636px 1666px #fff,
      692px 1112px #fff,
      248px 1211px #fff,
      1422px 1121px #fff,
      881px 46px #fff,
      1531px 1977px #fff,
      1643px 1023px #fff,
      684px 1071px #fff,
      1142px 1873px #fff,
      292px 1313px #fff,
      256px 1237px #fff,
      89px 912px #fff,
      964px 1783px #fff,
      877px 760px #fff,
      1641px 1474px #fff,
      1492px 24px #fff,
      1776px 1642px #fff,
      183px 602px #fff,
      1998px 62px #fff,
      1560px 367px #fff,
      1333px 995px #fff,
      704px 1815px #fff,
      1809px 712px #fff,
      1503px 288px #fff,
      630px 556px #fff,
      1715px 125px #fff,
      353px 1878px #fff,
      975px 333px #fff,
      1740px 1409px #fff,
      1341px 1871px #fff,
      1279px 1064px #fff,
      169px 874px #fff,
      161px 528px #fff,
      1671px 1669px #fff,
      169px 632px #fff,
      547px 1724px #fff,
      1904px 110px #fff,
      679px 1670px #fff,
      196px 123px #fff,
      786px 871px #fff,
      1840px 324px #fff,
      356px 967px #fff,
      61px 549px #fff,
      99px 677px #fff,
      1719px 87px #fff,
      1713px 1990px #fff,
      1717px 1358px #fff,
      108px 1187px #fff,
      51px 869px #fff,
      1461px 902px #fff,
      1034px 891px #fff,
      962px 1881px #fff,
      1723px 595px #fff,
      479px 901px #fff,
      1546px 1823px #fff,
      285px 1208px #fff,
      1056px 347px #fff,
      261px 988px #fff,
      466px 990px #fff,
      1657px 648px #fff,
      1249px 933px #fff,
      1552px 1555px #fff,
      147px 62px #fff,
      292px 1157px #fff,
      1816px 423px #fff,
      1714px 757px #fff,
      1036px 961px #fff,
      1955px 710px #fff,
      1842px 516px #fff,
      479px 1870px #fff,
      1579px 1445px #fff,
      1225px 1309px #fff,
      1965px 566px #fff,
      1575px 1072px #fff,
      923px 329px #fff,
      651px 1514px #fff,
      865px 1100px #fff,
      782px 1873px #fff,
      115px 299px #fff,
      14px 1668px #fff,
      1666px 1817px #fff,
      1096px 1068px #fff,
      1462px 742px #fff,
      1384px 1750px #fff;
  }
}